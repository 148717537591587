import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatCentsToCurrency } from "../../../../utils/numberHelpers";
import { blue, grayDarker, gray } from "../../../../styles/colors";

const QuantitySelector = styled.div`
  height: auto;
  margin: 0 0 1rem 1rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${blue};
  border-radius: 4px;
  &&& {
    p {
      margin: 0 1rem;
      color: ${grayDarker};
    }
  }
`;

const IconBox = styled.div`
  margin: 0;
  background: ${props => (props.disabled ? gray : blue)};
  padding: 0.25rem;
  border-radius: 4px;
  color: white;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

export default function AdditionalServiceSelect(props) {
  const {
    service,
    quantity,
    handleAdditionalServicesChange,
    isChecked
  } = props;

  const isPlural = service.maxQuantity > 1;

  const [quantitySelected, setQuantitySelected] = useState(quantity);

  const handleServiceCheck = e => {
    if (!e.target.checked) {
      setQuantitySelected(0);
      handleAdditionalServicesChange(service.id, 0);
    }
    if (e.target.checked) {
      setQuantitySelected(1);
      handleAdditionalServicesChange(service.id, 1);
    }
  };

  const handleQuantityDecrease = () => {
    handleAdditionalServicesChange(service.id, quantitySelected - 1);
    setQuantitySelected(quantitySelected - 1);
  };

  const handleQuantityIncrease = () => {
    if (quantitySelected === service.maxQuantity) {
      return;
    }
    handleAdditionalServicesChange(service.id, quantitySelected + 1);
    setQuantitySelected(quantitySelected + 1);
  };

  return (
    <div
      data-testid={`additional-service-wrapper-${service.id}`}
      style={{ display: "flex", justifyContent: "left" }}
    >
      <div className="checkbox-wrapper" style={{ width: "24rem" }}>
        <label className="input-button-label">
          <input
            type="checkbox"
            name={service.id}
            id={service.id}
            checked={isChecked}
            onChange={e => handleServiceCheck(e)}
          />
          <div className="checkbox">
            {service.name} ({formatCentsToCurrency(service.amount)})
          </div>
        </label>
      </div>
      {isChecked && isPlural && (
        <QuantitySelector>
          <IconBox
            data-testid="additional-service-decrease"
            onClick={() => handleQuantityDecrease()}
          >
            <i className="fas fa-minus" />
          </IconBox>
          <p>{quantitySelected}</p>
          <IconBox
            data-testid="additional-service-increase"
            disabled={quantitySelected === service.maxQuantity}
            onClick={() => handleQuantityIncrease()}
          >
            <i className="fas fa-plus" />
          </IconBox>
        </QuantitySelector>
      )}
    </div>
  );
}

AdditionalServiceSelect.propTypes = {
  service: PropTypes.object,
  handleAdditionalServicesChange: PropTypes.func,
  isChecked: PropTypes.bool
};
