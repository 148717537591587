import styled from "styled-components";
import { grayDark } from "../../../styles/colors";

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h3 {
    font-weight: 400;
    font-size: 21px;
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 18px;
    margin: 0.5rem 0;
  }

  p {
    color: ${grayDark};
  }

  a {
    text-decoration: underline;
    margin: 0.5rem 0;
  }
`;
