import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import styled from "styled-components";
import Select from "react-select";
import Loader from "react-loader-spinner";
import { formHeaders } from "../../../utils/form";
import BaseFeeRow from "./BaseFeeRow";
import { IndexRow } from "../../Shared/IndexRow/IndexRow";
import { generateUUID } from "../../../utils/uuidGenerator";
import { blue, gold } from "../../../styles/colors";
import { formatCentsToCurrency } from "../../../utils/numberHelpers";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect";

const FormInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${blue};
  border-radius: 4px;
  width: ${props => props.width};
`;

export default function WorkflowDetails(props) {
  const popup = useAlert();
  const { workflow, additionalServices, hasEngAccess } = props;
  const {
    additionalServices: workflowAdditionalServices,
    baseFees,
    id
  } = workflow;

  const baseFeesWithTempId = baseFees.map(fee => ({
    ...fee,
    amount: fee.amount,
    tempId: generateUUID()
  }));

  const [currentBaseFees, setCurrentBaseFees] = useState(baseFeesWithTempId);

  const [
    currentAdditionalServiceIds,
    setCurrentAdditionalServiceIds
  ] = useState(workflowAdditionalServices.map(as => as.id));

  const [baseFeeFormVisible, setBaseFeeFormVisible] = useState(false);
  const [baseFeeFormState, setBaseFeeFormState] = useState({
    name: "",
    amount: ""
  });

  const [updateBaseFees, setUpdateBaseFees] = useState(false);

  const defaultOptions = workflowAdditionalServices.map(as => ({
    value: as.id,
    label: `${as.name} - ${formatCentsToCurrency(as.amount)}`
  }));

  const serviceOptions = additionalServices.map(as => ({
    value: as.id,
    label: `${as.name} - ${formatCentsToCurrency(as.amount)}`
  }));

  const handleServicesSelect = values => {
    if (!values) {
      setCurrentAdditionalServiceIds([]);
    } else {
      setCurrentAdditionalServiceIds(values?.map(v => v.value));
    }
  };

  const handleBaseFeeFormChange = e => {
    setBaseFeeFormState({
      ...baseFeeFormState,
      [e.target.name]: e.target.value
    });
  };

  const createBaseFee = () => {
    setCurrentBaseFees(prevFees => [
      ...prevFees,
      {
        ...baseFeeFormState,
        amount: baseFeeFormState.amount * 100,
        tempId: generateUUID()
      }
    ]);
    setBaseFeeFormState({
      name: "",
      amount: ""
    });
    setBaseFeeFormVisible(false);
    setUpdateBaseFees(true);
  };

  const totalPrice = () => {
    const baseFeeCost = currentBaseFees.reduce(
      (a, bf) => a + parseInt(bf.amount),
      0
    );

    return formatCentsToCurrency(baseFeeCost);
  };

  const [loading, setLoading] = useState(false);

  const updateWorkflow = async () => {
    try {
      setLoading(true);

      const url = Routes.workflow_path(workflow.id);

      const requestBody = {
        workflow: {
          base_fees: currentBaseFees.map(({ name, amount }) => ({
            name,
            amount: parseInt(amount)
          })),
          additional_service_ids: currentAdditionalServiceIds
        }
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const json = await response.json();
        popup.show(
          `Something went wrong: \n ${response.status} ${response.statusText} \n ${json}`,
          { type: "error" }
        );
      }

      if (response.ok) {
        popup.show("Workflow successfully updated", { type: "success" });
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    } finally {
      setLoading(false);
      setUpdateBaseFees(false);
    }
  };

  useDidMountEffect(() => {
    if (updateBaseFees) updateWorkflow();
  }, [updateBaseFees]);

  return (
    <div data-testid={workflow.id}>
      <h3>{workflow.name}</h3>
      {additionalServices.length > 0 && (
        <strong>Base price: {totalPrice()}</strong>
      )}
      <h4>Base Fees</h4>
      {currentBaseFees.length > 0 && (
        <table style={{ display: "grid" }}>
          <tbody>
            <IndexRow>
              <th>Name</th>
              <th>Amount</th>
              <th />
            </IndexRow>
            {currentBaseFees.map(bf => (
              <BaseFeeRow
                key={bf.tempId}
                baseFee={bf}
                workflowId={id}
                setUpdateBaseFees={setUpdateBaseFees}
                setCurrentBaseFees={setCurrentBaseFees}
                hasEngAccess={hasEngAccess}
              />
            ))}
          </tbody>
        </table>
      )}
      {!baseFeeFormVisible && (
        <button
          type="button"
          className="text-button small secondary"
          tabIndex="0"
          onClick={() => setBaseFeeFormVisible(true)}
          disabled={!hasEngAccess}
          style={{ cursor: !hasEngAccess ? "not-allowed" : "pointer" }}
        >
          Add new fee
        </button>
      )}
      {baseFeeFormVisible && (
        <IndexRow as="div" data-testid="base-fee-form">
          <FormInput
            name="name"
            type="text"
            placeholder="Name"
            value={baseFeeFormState.name}
            onChange={e => handleBaseFeeFormChange(e)}
          />
          <div>
            $
            <FormInput
              name="amount"
              type="text"
              width="6rem"
              placeholder="Price"
              value={baseFeeFormState.amount}
              onChange={e => handleBaseFeeFormChange(e)}
            />
          </div>
          <div className="actions">
            <button
              type="button"
              className="small cancel u_margin-right-1rem"
              onClick={() => setBaseFeeFormVisible(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="small"
              onClick={() => createBaseFee()}
            >
              Save
            </button>
          </div>
        </IndexRow>
      )}
      <h4>Additional Services</h4>
      <Select
        styles={{
          multiValueRemove: base => ({
            ...base,
            backgroundColor: gold
          }),
          multiValueLabel: base => ({
            ...base,
            backgroundColor: gold
          })
        }}
        placeholder="Add additional services..."
        isMulti
        options={serviceOptions}
        className="additional-services-select"
        classNamePrefix="additional-service"
        defaultValue={defaultOptions}
        onChange={values => handleServicesSelect(values)}
      />
      {!loading && (
        <div className="u_margin-top-1rem">
          <button
            className="button small"
            type="button"
            onClick={() => updateWorkflow()}
            disabled={!hasEngAccess}
            style={{ cursor: !hasEngAccess ? "not-allowed" : "pointer" }}
          >
            Save
          </button>
        </div>
      )}
      {loading && (
        <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
      )}
    </div>
  );
}

WorkflowDetails.propTypes = {
  workflow: PropTypes.object
};
