/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DealRow from "../DealRow/DealRow";
import { usePagination } from "../../../../hooks/usePagination";
import { IndexRow } from "../../../Shared/IndexRow/IndexRow";
import { PaginationWidget } from "../../../Shared/PaginationWidget/PaginationWidget";
import { useDidMountEffect } from "../../../../hooks/useDidMountEffect";
import {
  alphabeticalSort,
  chronologicalSort,
  numericalSort
} from "../../../../utils/sorting";

const IndexGrid = styled.div`
  display: grid;
`;

export default function DealsTable(props) {
  const {
    groupName,
    dealGroup,
    maxPerPage = 10,
    setDealsState,
    searchString
  } = props;

  const [showingAll, setShowingAll] = useState(false);
  const [sortBy, setSortBy] = useState({
    attribute: "updatedAt",
    ascending: true
  });

  const handleSort = attribute => {
    setSortBy(prevState => ({
      attribute,
      ascending: prevState.attribute === attribute ? !sortBy.ascending : true
    }));
  };

  const currentSortFunction = (a, b) => {
    switch (sortBy.attribute) {
      case "beneficiaryName":
        return alphabeticalSort(
          a.dealBeneficiaries[0],
          b.dealBeneficiaries[0],
          sortBy.ascending
        );
      case "authorName":
        return alphabeticalSort(
          a.author.fullName,
          b.author.fullName,
          sortBy.ascending
        );
      case "state":
        return alphabeticalSort(a.state, b.state, sortBy.ascending);
      case "total":
        return numericalSort(
          a.finalPriceData.pay_total,
          b.finalPriceData.pay_total,
          sortBy.ascending
        );
      case "updatedAt":
        return chronologicalSort(a.updatedAt, b.updatedAt, sortBy.ascending);
      // By default deals will be sorted by last updated
      default:
        return chronologicalSort(a.updatedAt, b.updatedAt, sortBy.ascending);
    }
  };

  const currentSortArrow = attribute => {
    if (sortBy.attribute === attribute && sortBy.ascending) return "↑";
    if (sortBy.attribute === attribute && !sortBy.ascending) return "↓";
  };

  const sortedDealGroup = dealGroup.sort((a, b) => currentSortFunction(a, b));

  const {
    nextPage,
    prevPage,
    currentPage,
    maxPage,
    showAll,
    setCurrentPage,
    repaginate,
    currentData,
    startIndex,
    endIndex
  } = usePagination(sortedDealGroup, maxPerPage);

  useDidMountEffect(() => {
    setCurrentPage(1);
  }, [searchString]);

  const handleShowAllClick = () => {
    window.location = `#${groupName.toLowerCase().replace(/\s/g, "")}`;
    window.scrollBy(0, -70);
    showAll();
    setShowingAll(true);
  };

  const handleRepaginateClick = () => {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    );
    repaginate();
    setShowingAll(false);
  };

  return (
    <>
      {dealGroup.length === 0 && <p>No results found...</p>}
      {dealGroup.length > 0 && (
        <PaginationWidget
          currentPage={currentPage}
          maxPage={maxPage}
          showingAll={showingAll}
          upshift
        >
          <p>{`${startIndex} - ${endIndex} of ${dealGroup.length}`}</p>
          {dealGroup.length > maxPerPage && (
            <div>
              {!showingAll && (
                <a data-turbolinks={false} onClick={() => handleShowAllClick()}>
                  Show all
                </a>
              )}
              {showingAll && (
                <a onClick={() => handleRepaginateClick()}>Back to page view</a>
              )}
            </div>
          )}
          {dealGroup.length > maxPerPage && (
            <div>
              <i
                data-testid="prev-page"
                onClick={() => prevPage()}
                className="fas fa-chevron-left"
              />
              <i
                data-testid="next-page"
                onClick={() => nextPage()}
                className="fas fa-chevron-right"
              />
            </div>
          )}
        </PaginationWidget>
      )}
      <IndexGrid>
        {dealGroup.length > 0 && (
          <IndexRow as="div">
            <div
              data-testid="deal"
              onClick={() => handleSort("beneficiaryName")}
            >
              Beneficiary Information {currentSortArrow("beneficiaryName")}
            </div>
            <div
              data-testid="account-executive"
              onClick={() => handleSort("authorName")}
            >
              Account Executive {currentSortArrow("authorName")}
            </div>
            <div data-testid="deal-total" onClick={() => handleSort("total")}>
              Total {currentSortArrow("total")}
            </div>
            <div data-testid="deal-state" onClick={() => handleSort("state")}>
              Status {currentSortArrow("state")}
            </div>
            <div
              data-testid="deal-last-modified"
              onClick={() => handleSort("updatedAt")}
            >
              Last modified {currentSortArrow("updatedAt")}
            </div>
          </IndexRow>
        )}
        {currentData().map(deal => (
          <DealRow key={deal.id} deal={deal} setDealsState={setDealsState} />
        ))}
      </IndexGrid>
    </>
  );
}

DealsTable.propTypes = {
  groupName: PropTypes.string,
  dealGroup: PropTypes.array,
  maxPerPage: PropTypes.number,
  setDealsState: PropTypes.func
};
