import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { formHeaders } from "../../../utils/form";
import SelectBox from "../SelectBox";
import RunningTotal from "../RunningTotal/RunningTotal";
import { uniqueArrayByProp } from "../../../utils/arrayMethods";
import BundleOptions from "./BundleOptions";
import ActionFrame from "../ActionFrame";
import { generateUUID } from "../../../utils/uuidGenerator";
import OrganizationPersonForm from "../../Shared/Forms/OrganizationPersonForm";
import { Section } from "../SalesFlowComponents/index";
import { useFormState } from "../FormStateProvider";

const BeneficiaryContainer = styled.div`
  margin: 1rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid lightgray;
`;

export default function BeneficiariesStep(props) {
  const popup = useAlert();
  const history = useHistory();

  const [, setFormState] = useFormState();

  const {
    deal,
    setDeal,
    workflows,
    organizationPeople = [],
    setOrganizationPeople,
    stepsArray = [],
    currentStep = {}
  } = props;

  const { id: dealId, billingPerson, petitionBundles = [] } = deal;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [beneficiaryOptions, setBeneficiaryOptions] = useState(
    uniqueArrayByProp("id", [...organizationPeople, billingPerson])
  );

  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);

  // Local bundle state shaped differently than what is returned from the server on this step
  // No petition exists when bundles are instantiated on the client

  const dealBundles = useMemo(
    () =>
      petitionBundles.map(b => ({
        id: b.id,
        tempId: null,
        beneficiary: b.petition.beneficiary,
        workflowId: b.petition.workflow.id,
        additionalServiceIds: b.additionalServiceIds,
        petitionId: b.petition.id
      })),
    [petitionBundles]
  );

  const [currentBundles, setCurrentBundles] = useState(dealBundles);

  const createBundle = ({
    beneficiary,
    workflowId,
    additionalServiceIds = []
  }) => ({
    id: null,
    tempId: generateUUID(),
    beneficiary,
    workflowId,
    additionalServiceIds
  });

  const handleBeneficiarySelect = (e, beneficiary) => {
    if (e.target.checked) {
      setSelectedBeneficiaries([...selectedBeneficiaries, beneficiary]);
    }

    if (!e.target.checked) {
      setSelectedBeneficiaries(
        selectedBeneficiaries.filter(b => b.id !== beneficiary.id)
      );
      const newBundles = currentBundles.filter(
        bundle => bundle.beneficiary.id !== beneficiary.id
      );
      setCurrentBundles(newBundles);
    }
  };

  const updateDeal = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      const url = Routes.deal_path(dealId);

      const requestBody = {
        petition_bundles_attributes: currentBundles.map(bundle => ({
          id: bundle.id,
          additional_service_ids: bundle.additionalServiceIds,
          petition_attributes: {
            id: bundle.petitionId,
            workflow_id: bundle.workflowId,
            beneficiary_id: bundle.beneficiary.id
          }
        }))
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const json = await response.json();
        setLoading(false);
        setErrors([...errors, json.errors]);
        json.errors.forEach(err => {
          setErrors([...errors, err]);
          popup.show(err, { type: "error" });
        });
      }

      if (response.ok) {
        const json = await response.json();
        setDeal(json);
        setLoading(false);
        setFormState({ dirty: false });
        history.push(`/deals/${deal.id}/contacts`);
        popup.show("Draft deal has been saved!", {
          type: "success",
          timeout: 1500
        });
      }
    } catch (error) {
      setLoading(false);
      popup.show(error.message, { type: "error" });
    }
  };

  useEffect(() => {
    if (deal.petitionBundles) {
      const optionsWithBundles = deal.petitionBundles.reduce((a, b) => {
        a.push(b.petition?.beneficiary);
        return a;
      }, selectedBeneficiaries);
      const uniqueOptions = uniqueArrayByProp("id", optionsWithBundles);
      setSelectedBeneficiaries(uniqueOptions);
      setCurrentBundles(dealBundles);
    }
  }, [deal]);

  const addNewBundleToState = beneficiary => {
    setCurrentBundles([...currentBundles, createBundle({ beneficiary })]);
  };

  const handleNewBeneficiary = newPerson => {
    setBeneficiaryOptions(prevState => [...prevState, newPerson]);
    setSelectedBeneficiaries(prevState => [...prevState, newPerson]);
    setOrganizationPeople(prevState => [...prevState, newPerson]);
  };

  useEffect(() => {
    if (currentBundles !== dealBundles) {
      setFormState({ dirty: true });
    } else {
      setFormState({ dirty: false });
    }
    return () => setFormState({ dirty: false });
  }, [currentBundles]);

  const unselectedBeneficiaries = beneficiaryOptions.filter(
    option => !selectedBeneficiaries.map(sb => sb.id).includes(option.id)
  );

  const disableContinue = !currentBundles[0]?.workflowId;

  return (
    <>
      <Section>
        <div>
          <h3 style={{ margin: "1rem 0" }}>Choose beneficiaries</h3>
          {selectedBeneficiaries.map(beneficiary => {
            const beneficiaryBundles = currentBundles.filter(
              bundle => bundle.beneficiary.id === beneficiary.id
            );
            const savedBeneficiaryBundles = beneficiaryBundles.filter(
              bundle => bundle.id !== null && bundle.id !== undefined
            );

            if (beneficiaryBundles.length === 0) {
              addNewBundleToState(beneficiary);
            }

            return (
              <div key={beneficiary.id}>
                <SelectBox
                  contact={beneficiary}
                  checked={selectedBeneficiaries.includes(beneficiary)}
                  disabled={savedBeneficiaryBundles.length > 0}
                  name={`beneficiary-select-${beneficiary.id}`}
                  handleChange={e => handleBeneficiarySelect(e, beneficiary)}
                />
                <BeneficiaryContainer
                  data-testid={`beneficiary-container-for-${beneficiary.id}`}
                  className="beneficiary-container"
                >
                  {beneficiaryBundles.map((bundle, i) => (
                    <div key={bundle.id ?? bundle.tempId}>
                      <div>
                        <BundleOptions
                          index={i + 1}
                          bundle={bundle}
                          beneficiary={beneficiary}
                          workflows={workflows}
                          createBundle={createBundle}
                          currentBundles={currentBundles}
                          setCurrentBundles={setCurrentBundles}
                        />
                      </div>
                      {/* Render the add visa button only at the end of the bundle mapping if a workflow is selected */}
                      {!!bundle.workflowId &&
                        i === beneficiaryBundles.length - 1 && (
                          <a
                            role="button"
                            onClick={() => addNewBundleToState(beneficiary)}
                            onKeyDown={() => addNewBundleToState(beneficiary)}
                            tabIndex={0}
                          >
                            Add another visa type for{" "}
                            {beneficiary?.name ?? "this beneficiary"}
                          </a>
                        )}
                    </div>
                  ))}
                </BeneficiaryContainer>
              </div>
            );
          })}

          {unselectedBeneficiaries.length > 0 && (
            <p style={{ fontSize: "14px" }}>Existing contacts</p>
          )}
          {unselectedBeneficiaries.map(beneficiary => (
            <div key={beneficiary.id}>
              <SelectBox
                contact={beneficiary}
                checked={selectedBeneficiaries.includes(beneficiary)}
                handleChange={e => handleBeneficiarySelect(e, beneficiary)}
              />
            </div>
          ))}
          <OrganizationPersonForm
            organizationId={deal.organization?.id}
            updateParentObject={handleNewBeneficiary}
            personType="beneficiary"
          />
        </div>
        <RunningTotal petitionBundles={currentBundles} workflows={workflows} />
      </Section>
      <ActionFrame
        loading={loading}
        continueDisabled={disableContinue}
        stepsArray={stepsArray}
        currentStep={currentStep}
        handleContinue={() => updateDeal()}
        hasErrors={errors.length > 0}
      />
    </>
  );
}

BeneficiariesStep.propTypes = {
  deal: PropTypes.object,
  stepsArray: PropTypes.array,
  currentStep: PropTypes.object
};
