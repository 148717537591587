import React, { createContext, useState, useContext } from "react";

const FormContext = createContext(null);

export const useFormState = () => {
  const formContextState = useContext(FormContext);

  if (formContextState === null) {
    throw new Error(
      "useFormState must be used within a FormContext.Provider tag"
    );
  }
  return formContextState;
};

export const FormContextProvider = ({ children }) => {
  const [state, setState] = useState({ dirty: false });
  return (
    <FormContext.Provider value={[state, setState]}>
      {children}
    </FormContext.Provider>
  );
};
