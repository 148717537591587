import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import { IndexRow } from "../../Shared/IndexRow/IndexRow";
import { formHeaders } from "../../../utils/form";
import { deepEqual } from "../../../utils/equalityHelpers";
import { CheckboxItem } from "../../Shared/CheckBox";

const Input = styled.input`
  border: none;
  width: ${props => props.width ?? "auto"};
  cursor: ${props => (props.disabled ? "not-allowed" : "auto")};
`;

export default function AdditionalServiceRow(props) {
  const popup = useAlert();
  const { additionalService, setAdditionalServices, hasEngAccess } = props;

  const priceAdjustedAdditionalService = useMemo(
    () => ({
      ...additionalService,
      amount: additionalService.amount / 100
    }),
    [additionalService]
  );
  const [currentAdditionalService, setCurrentAdditionalService] = useState(
    priceAdjustedAdditionalService
  );

  const [serviceDirty, setServiceDirty] = useState(
    currentAdditionalService !== priceAdjustedAdditionalService
  );

  useEffect(() => {
    setCurrentAdditionalService(priceAdjustedAdditionalService);
  }, [additionalService]);

  useEffect(() => {
    if (deepEqual(currentAdditionalService, priceAdjustedAdditionalService)) {
      setServiceDirty(false);
    } else {
      setServiceDirty(true);
    }
  }, [currentAdditionalService, priceAdjustedAdditionalService]);

  const [loading, setLoading] = useState(false);

  const updateAdditionalService = async () => {
    try {
      setLoading(true);

      const url = Routes.additional_service_path(additionalService.id);

      const requestBody = {
        additional_service: {
          name: currentAdditionalService.name,
          amount: currentAdditionalService.amount * 100,
          is_default_option: currentAdditionalService.isDefaultOption,
          max_quantity: currentAdditionalService.maxQuantity
        }
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const json = await response.json();
        popup.show(
          `Something went wrong: \n ${response.status} ${response.statusText} \n ${json}`,
          { type: "error" }
        );
      }

      if (response.ok) {
        popup.show("Service successfully updated", { type: "success" });
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    } finally {
      setLoading(false);
      setServiceDirty(false);
    }
  };

  const deleteAdditionalService = async () => {
    try {
      const url = Routes.additional_service_path(additionalService.id);

      const response = await fetch(url, {
        method: "DELETE",
        headers: formHeaders()
      });

      if (!response.ok) {
        popup.show(
          `Something went wrong: \n ${response.status} ${response.statusText}`,
          { type: "error" }
        );
      }

      if (response.ok) {
        setAdditionalServices(prevAdditionalServices =>
          prevAdditionalServices.filter(as => as.id !== additionalService.id)
        );
        popup.show("Service successfully destroyed", { type: "success" });
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    }
  };

  const handleCancel = () => {
    setCurrentAdditionalService(priceAdjustedAdditionalService);
    if ("activeElement" in document) document.activeElement.blur();
    setServiceDirty(false);
  };

  const handleDelete = () =>
    // eslint-disable-next-line no-restricted-globals
    confirm(
      "Are you sure you want to delete this service? This cannot be undone."
    ) && deleteAdditionalService();

  const handleAdditionalServiceChange = (e, parseInts) => {
    setCurrentAdditionalService({
      ...currentAdditionalService,
      [e.target.name]: parseInts ? parseInt(e.target.value) : e.target.value
    });
  };

  const handleAdditionalServiceDefaultCheck = e => {
    setCurrentAdditionalService({
      ...currentAdditionalService,
      [e.target.name]: e.target.checked
    });
  };

  return (
    <IndexRow data-testid={additionalService.id}>
      <td>
        <Input
          name="name"
          value={currentAdditionalService.name}
          onChange={e => handleAdditionalServiceChange(e)}
          style={{ fontWeight: 600 }}
          disabled={!hasEngAccess || loading}
        />
      </td>
      <td>
        $
        <Input
          name="amount"
          width="6rem"
          type="number"
          step={20}
          value={currentAdditionalService.amount}
          onChange={e => handleAdditionalServiceChange(e, true)}
          disabled={!hasEngAccess || loading}
        />
      </td>
      <td>
        <Input
          name="maxQuantity"
          width="3rem"
          type="number"
          value={currentAdditionalService.maxQuantity}
          onChange={e => handleAdditionalServiceChange(e, true)}
          disabled={!hasEngAccess || loading}
        />
      </td>
      <td>
        <CheckboxItem disabled={!hasEngAccess}>
          <input
            name="isDefaultOption"
            type="checkbox"
            checked={currentAdditionalService.isDefaultOption}
            onChange={e => handleAdditionalServiceDefaultCheck(e)}
            disabled={!hasEngAccess || loading}
          ></input>
          <label>{currentAdditionalService.isDefaultOption?.toString()}</label>
        </CheckboxItem>
      </td>
      {serviceDirty && !loading ? (
        <td className="actions">
          <button
            type="button"
            className="small cancel u_margin-right-1rem"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="small"
            onClick={() => updateAdditionalService()}
          >
            Save
          </button>
        </td>
      ) : (
        <td />
      )}
      {loading && (
        <Loader type="ThreeDots" color="#2680FA" height={18} width={64} />
      )}
      <td className="actions">
        <button
          type="button"
          className="button text-button no-padding destructive"
          onClick={() => handleDelete()}
          onKeyDown={() => handleDelete()}
          disabled={!hasEngAccess || loading}
        >
          DELETE
        </button>
      </td>
    </IndexRow>
  );
}

AdditionalServiceRow.propTypes = {
  additionalService: PropTypes.object,
  setAdditionalServices: PropTypes.func
};
