import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { green } from "../../../styles/colors";
import { formatCentsToCurrency } from "../../../utils/numberHelpers";
import { uniqueArrayByProp } from "../../../utils/arrayMethods";

const Container = styled.div`
  position: sticky;
  top: 25px;
  bottom: 25px;
  width: 20vw;
  border-radius: 2px;
  background-color: white;
  padding: 1rem 1rem 1.5rem 1rem;
  text-align: left;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  min-width: 16rem;

  h4,
  h5 {
    margin: 0;
  }
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 12px;
  }
  .custom-discount-line-item {
    margin-right: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default function RunningTotal(props) {
  const {
    petitionBundles = [],
    discounts = [],
    workflows = [],
    selectedPlan,
    setPayTotal
  } = props;

  const queryWorkflowById = workflowId => {
    if (workflowId) {
      const { totalBaseFees, name: workflowName } = workflows.find(
        wf => wf.id === workflowId
      );
      return [totalBaseFees, workflowName];
    }
  };

  const queryAdditionalServiceById = serviceId => {
    const allAdditionalServices = [];
    workflows.forEach(wf => allAdditionalServices.push(wf.additionalServices));
    const uniqueServices = uniqueArrayByProp(
      "id",
      allAdditionalServices.flat()
    );
    const { name: serviceName, amount: serviceAmount } = uniqueServices.find(
      as => as.id === serviceId
    );
    return [serviceName, serviceAmount];
  };

  const totalPrice = petitionBundles.reduce((total, currentBundle) => {
    if (currentBundle.workflowId || currentBundle.petition?.workflow.id) {
      const [totalBaseFees] = queryWorkflowById(
        currentBundle.workflowId ?? currentBundle.petition?.workflow.id
      );
      const totalAdditionalServiceFees = currentBundle.additionalServiceIds.reduce(
        (totalServiceFees, currentServiceId) => {
          const [, serviceAmount] = queryAdditionalServiceById(
            currentServiceId
          );
          return totalServiceFees + serviceAmount;
        },
        0
      );
      return total + totalBaseFees + totalAdditionalServiceFees;
    }
    return total;
  }, 0);

  const totalDiscounts = discounts.reduce(
    (total, currentValue) => total + currentValue.amount,
    0
  );

  useEffect(() => {
    if (setPayTotal) setPayTotal(totalPrice - totalDiscounts);
  }, [totalPrice, totalDiscounts]);

  return (
    <Container data-testid="running-total-container">
      <h4>Summary</h4>
      {petitionBundles.map(bundle => {
        if (bundle.workflowId || bundle.petition?.workflow.id) {
          // necessary because workflowId is not on the bundle after the beneficiary step
          const idForQuery = bundle.workflowId ?? bundle.petition?.workflow.id;
          const [totalBaseFees, workflowName] = queryWorkflowById(idForQuery);
          const totalBundleAdditionalServiceFees = bundle.additionalServiceIds.reduce(
            (totalBundleServiceFees, currentServiceId) => {
              const [, serviceAmount] = queryAdditionalServiceById(
                currentServiceId
              );
              return totalBundleServiceFees + serviceAmount;
            },
            0
          );

          return (
            <div
              key={bundle.id ?? bundle.tempId}
              style={{ marginBottom: "0.5rem" }}
            >
              <LineItem>
                <h6>{workflowName}</h6>
                <h6>
                  {formatCentsToCurrency(
                    totalBaseFees + totalBundleAdditionalServiceFees
                  )}
                </h6>
              </LineItem>
              {bundle.additionalServiceIds.map((serviceId, i) => {
                const [name, amount] = queryAdditionalServiceById(serviceId);
                return (
                  <LineItem key={`${serviceId}-${i}`}>
                    <p>{name}</p>
                    <p>{formatCentsToCurrency(amount)}</p>
                  </LineItem>
                );
              })}
            </div>
          );
        }
        return <div key={bundle.id}></div>;
      })}
      {discounts.length > 0 && (
        <>
          <LineItem data-testid="discounts-line-item">
            <h6>Discounts</h6>
            <h6 style={{ color: green }}>
              -{formatCentsToCurrency(totalDiscounts)}
            </h6>
          </LineItem>
          {discounts.map(discount => (
            <LineItem key={discount.id ?? discount.uniqueId}>
              <p className="custom-discount-line-item">{discount.name}</p>
              <p>-{formatCentsToCurrency(discount.amount)}</p>
            </LineItem>
          ))}
        </>
      )}
      <hr />
      <LineItem data-testid="pay-total-line-item">
        <h5 style={selectedPlan > 1 ? { fontWeight: 400 } : null}>Pay Total</h5>
        <h5 style={selectedPlan > 1 ? { fontWeight: 400 } : null}>
          {formatCentsToCurrency(totalPrice - totalDiscounts)}
        </h5>
      </LineItem>
      {selectedPlan && selectedPlan > 1 && (
        <>
          <LineItem data-testid="installments-line-item">
            <p>Over {selectedPlan} months</p>
          </LineItem>
          <br />
          <LineItem data-testid="first-payment-line-item">
            <h5>First payment</h5>
            <h5>
              {formatCentsToCurrency(
                (totalPrice - totalDiscounts) / selectedPlan
              )}
            </h5>
          </LineItem>
        </>
      )}
    </Container>
  );
}

RunningTotal.propTypes = {
  petitionBundles: PropTypes.array,
  discounts: PropTypes.array
};
