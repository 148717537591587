import React, { useEffect } from "react";
import { useLocation, Redirect, Switch, Route, Prompt } from "react-router-dom";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import StepContent from "./StepContent";
import PetitionerStep from "./PetitionerStep/PetitionerStep";
import BeneficiariesStep from "./BeneficiariesStep/BeneficiariesStep";
import PointOfContactStep from "./PointOfContactStep/PointOfContactStep";
import ReviewStep from "./ReviewStep/ReviewStep";
import StepNav from "./StepNav";
import DealShowPage from "./DealShowPage/DealShowPage";
import { useFormState } from "./FormStateProvider";
import SalesDashboard from "./SalesDashboard/SalesDashboard";
import CommonNav from "../Shared/CommonNav/CommonNav";

const StepWrapper = styled.section`
  margin-top: 4rem;
`;

export default function RouteWrapper(props) {
  const {
    deal,
    setDeal,
    dealLoading,
    fixturesLoading,
    petitionerItems,
    organizationId,
    setOrganizationId,
    organizationPeople,
    setOrganizationPeople,
    workflows
  } = props;

  const [formState, ,] = useFormState();

  const petitionerStepPath = deal.id
    ? `/deals/${deal.id}/petitioner`
    : "/deals/new";

  const stepsArray = [
    {
      title: "Petitioner Information",
      pathname: petitionerStepPath
    },
    {
      title: "Beneficiaries & Visas",
      pathname: `/deals/${deal.id}/beneficiaries`,
      description: "Set Beneficiaries"
    },
    {
      title: "Point of Contact",
      pathname: `/deals/${deal.id}/contacts`,
      description: "Set Points of Contact"
    },
    {
      title: "Review",
      pathname: `/deals/${deal.id}/review`,
      description: "Review information"
    }
  ];

  const { pathname } = useLocation();

  const currentStep = stepsArray.find(step => step.pathname === pathname);

  const renderPetitionerStep = () => (
    <StepWrapper>
      <StepNav deal={deal} />
      <StepContent step={stepsArray[0]}>
        {dealLoading || fixturesLoading ? (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        ) : (
          <PetitionerStep
            petitionerItems={petitionerItems}
            deal={deal}
            setDeal={setDeal}
            stepsArray={stepsArray}
            currentStep={currentStep}
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
          />
        )}
      </StepContent>
    </StepWrapper>
  );

  const renderBeneficiariesStep = () => (
    <StepWrapper>
      <StepNav deal={deal} />
      <StepContent step={stepsArray[1]}>
        {dealLoading || fixturesLoading ? (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        ) : (
          <BeneficiariesStep
            deal={deal}
            setDeal={setDeal}
            workflows={workflows}
            organizationPeople={organizationPeople}
            setOrganizationPeople={setOrganizationPeople}
            stepsArray={stepsArray}
            currentStep={currentStep}
          />
        )}
      </StepContent>
    </StepWrapper>
  );

  const renderContactsStep = () => (
    <StepWrapper>
      <StepNav deal={deal} />

      <StepContent step={stepsArray[2]}>
        {dealLoading || fixturesLoading ? (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        ) : (
          <PointOfContactStep
            organizationId={organizationId}
            organizationPeople={organizationPeople}
            setOrganizationPeople={setOrganizationPeople}
            workflows={workflows}
            deal={deal}
            setDeal={setDeal}
            stepsArray={stepsArray}
            currentStep={currentStep}
          />
        )}
      </StepContent>
    </StepWrapper>
  );

  const renderReviewStep = () => (
    <StepWrapper>
      <StepNav deal={deal} />
      <StepContent step={stepsArray[3]}>
        {dealLoading || fixturesLoading ? (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        ) : (
          <ReviewStep
            deal={deal}
            setDeal={setDeal}
            workflows={workflows}
            stepsArray={stepsArray}
            currentStep={currentStep}
          />
        )}
      </StepContent>
    </StepWrapper>
  );

  // Scroll restoration between steps
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    document.getElementById("sales-container")?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {/* Base url is /sales */}
      <Route exact path="/services">
        <SalesDashboard />
      </Route>
      <Route exact path="/workflows">
        <SalesDashboard />
      </Route>
      <Route exact path="/deals">
        <SalesDashboard />
      </Route>
      <Route
        path="/deals"
        render={({ match: { url } }) => (
          <>
            <CommonNav
              home={{ text: "Sales", href: "/sales" }}
              useBrowserRouter
            />
            <Prompt
              when={formState.dirty}
              message="You have unsaved changes. Leave this page and discard them?"
            />
            <Switch>
              {/* <Route exact path={`${url}/`}>
                <DealIndexPage />
              </Route> */}
              <Route exact path={`${url}/new`}>
                {renderPetitionerStep()}
              </Route>
              <Route exact path={`${url}/:id`}>
                {deal.id && <DealShowPage deal={deal} />}
              </Route>
              <Route exact path={`${url}/:id/petitioner`}>
                {renderPetitionerStep()}
              </Route>
              <Route exact path={`${url}/:id/beneficiaries`}>
                {renderBeneficiariesStep()}
              </Route>
              <Route exact path={`${url}/:id/contacts`}>
                {renderContactsStep()}
              </Route>
              <Route exact path={`${url}/:id/review`}>
                {renderReviewStep()}
              </Route>
              <Redirect to={`${url}/new`} />
            </Switch>
          </>
        )}
      />
      <Redirect to="/deals" />
    </Switch>
  );
}
