import { render, fireEvent, waitFor } from "@testing-library/react";
import SalesFlowWrapper from "./SalesFlowWrapper";
import {
  mockOrganizationItems,
  mockWorkflows
} from "../../testHelpers/salesFlowMocks";
import { mockUser } from "../../testHelpers/userMocks";

describe("The Salesflow Petitioner Step", () => {
  const petitionerPlaceholderText =
    "Choose an organization or enter a new one...";

  beforeEach(() => {
    fetch.mockResponses(
      [JSON.stringify(mockUser), { status: 200 }],
      [JSON.stringify(mockWorkflows), { status: 200 }]
    );
  });
  afterEach(() => fetch.resetMocks());

  it("renders without crashing", async () => {
    const { container } = render(<SalesFlowWrapper />);
    await waitFor(() => expect(fetch.mock.calls.length).toEqual(5));
    expect(container);
  });

  it("allows the user to enter in a new petitioner organization", async () => {
    const { getByText, getByPlaceholderText } = render(<SalesFlowWrapper />);
    await waitFor(() => getByText("Sales"));
    fireEvent.click(getByText("New Deal"));
    await waitFor(() => getByPlaceholderText(petitionerPlaceholderText));
    fireEvent.change(getByPlaceholderText(petitionerPlaceholderText), {
      target: { value: "JumboCorp" }
    });
    await waitFor(() =>
      expect(getByPlaceholderText(petitionerPlaceholderText).value).toBe(
        "JumboCorp"
      )
    );
  });

  it("allows the user to select from an existing organization via autocomplete", async () => {
    fetch.once(JSON.stringify(mockOrganizationItems));
    const { getByPlaceholderText, getByText } = render(<SalesFlowWrapper />);
    await waitFor(() => getByPlaceholderText(petitionerPlaceholderText));
    fireEvent.change(getByPlaceholderText(petitionerPlaceholderText), {
      target: { value: "B" }
    });
    // labels use <strong> tags to emphasise matching search characters
    expect(getByText("B"));
    expect(getByText("igco"));
  });

  it("cannot navigate to the beneficiaries/visa step without a deal in state", async () => {
    const { getByPlaceholderText, getByText } = render(<SalesFlowWrapper />);
    await waitFor(() => getByPlaceholderText(petitionerPlaceholderText));
    fireEvent.click(getByText("Continue"));
    expect(getByText("Petitioner Information"));
  });

  it("allows the user to enter in a new petitioner organization", async () => {
    const { getByPlaceholderText } = render(<SalesFlowWrapper />);
    await waitFor(() => getByPlaceholderText(petitionerPlaceholderText));
    fireEvent.change(getByPlaceholderText(petitionerPlaceholderText), {
      target: { value: "JumboCorp" }
    });
    await waitFor(() =>
      expect(getByPlaceholderText(petitionerPlaceholderText).value).toBe(
        "JumboCorp"
      )
    );
  });

  it("allows the user to continue and hits the API when there is a Petitioner in state", async () => {
    fetch.once(JSON.stringify(mockOrganizationItems));
    const { getByPlaceholderText, getByText } = render(<SalesFlowWrapper />);
    await waitFor(() => getByPlaceholderText(petitionerPlaceholderText));
    fireEvent.change(getByPlaceholderText(petitionerPlaceholderText), {
      target: { value: "B" }
    });
    // labels use <strong> tags to emphasise matching search characters
    expect(getByText("B"));
    expect(getByText("igco"));
    fireEvent.click(getByText("igco"));
    fireEvent.click(getByText("Continue"));
    await waitFor(() => {
      expect(getByText("Beneficiaries & Visas")[1]);
    });
  });
});
