import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import ActionFrame from "../ActionFrame";
import SelectBox from "../SelectBox";
import { uniqueArrayByProp } from "../../../utils/arrayMethods";
import { formHeaders } from "../../../utils/form";
import OrganizationPersonForm from "../../Shared/Forms/OrganizationPersonForm";
import RunningTotal from "../RunningTotal/RunningTotal";
import { Section } from "../SalesFlowComponents/index";
import { useFormState } from "../FormStateProvider";

const Container = styled.div`
  h5 {
    font-size: 21px;
  }

  p {
    font-size: 14px;
  }
`;

export default function PointOfContactStep(props) {
  const {
    organizationPeople,
    setOrganizationPeople,
    workflows,
    deal,
    setDeal,
    stepsArray,
    currentStep
  } = props;

  const {
    id: dealId,
    billingPerson,
    petitionBundles = [],
    dealDiscounts
  } = deal;

  const contactOptions = uniqueArrayByProp("id", [
    ...organizationPeople,
    billingPerson
  ]);

  const [, setFormState] = useFormState();

  const [currentBundles, setCurrentBundles] = useState(petitionBundles);

  const updateBundlePoc = (bundleId, person) => {
    const updatedBundles = currentBundles.map(bundle => {
      if (bundleId === bundle.id) {
        return {
          ...bundle,
          petition: {
            ...bundle.petition,
            representative: person
          }
        };
      }
      return bundle;
    });
    setCurrentBundles(updatedBundles);
  };

  const popup = useAlert();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const updateDeal = async () => {
    try {
      setLoading(true);

      const url = Routes.deal_path(dealId);

      const requestBody = {
        petition_bundles_attributes: currentBundles.map(bundle => ({
          id: bundle.id,
          petition_attributes: {
            id: bundle.petition.id,
            representative_id: bundle.petition.representative.id
          }
        }))
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const json = await response.json();
        json.errors.forEach(err => {
          popup.show(err, { type: "error" });
        });
        setLoading(false);
      }

      if (response.ok) {
        const json = await response.json();
        setDeal(json);
        setLoading(false);
        setFormState({ dirty: false });
        history.push(`/deals/${json.id}/review`);
        popup.show("Draft deal has been saved!", {
          type: "success",
          timeout: 1500
        });
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    }
  };

  useEffect(() => {
    if (petitionBundles) {
      setCurrentBundles(petitionBundles);
    }
  }, [petitionBundles]);

  useEffect(() => {
    if (currentBundles !== petitionBundles) {
      setFormState({ dirty: true });
    } else {
      setFormState({ dirty: false });
    }
    return () => setFormState({ dirty: false });
  }, [currentBundles, petitionBundles]);

  const notAllContactsSelected = () =>
    currentBundles.some(bundle => !bundle.petition.representative.id);

  const disableContinue = notAllContactsSelected();

  return (
    <Container>
      <Section>
        <div>
          {currentBundles.map(bundle => {
            const handleNewContact = async newPerson => {
              setOrganizationPeople(prevState => [...prevState, newPerson]);
              updateBundlePoc(bundle.id, newPerson);
            };

            const { beneficiary, workflow } = bundle.petition;

            return (
              <div
                key={bundle.id}
                data-testid={`point-of-contact-container-for-bundle-${bundle.id}`}
                className="point-of-contact-container"
              >
                <h5>
                  For {beneficiary.firstName} {beneficiary.lastName}{" "}
                  {workflow.name}
                </h5>
                <p>Existing contacts</p>
                {contactOptions.map(person => (
                  <SelectBox
                    name={`select-box-${person.id}`}
                    key={person.id}
                    contact={person}
                    handleChange={() => updateBundlePoc(bundle.id, person)}
                    checked={bundle.petition.representative.id === person.id}
                  />
                ))}
                <OrganizationPersonForm
                  organizationId={deal.organization.id}
                  updateParentObject={handleNewContact}
                  personType="point of contact"
                />
              </div>
            );
          })}
        </div>
      </Section>
      <ActionFrame
        loading={loading}
        continueDisabled={disableContinue}
        stepsArray={stepsArray}
        currentStep={currentStep}
        handleContinue={() => updateDeal()}
      />
    </Container>
  );
}

PointOfContactStep.propTypes = {
  organizationPeople: PropTypes.array,
  setOrganizationPeople: PropTypes.func,
  deal: PropTypes.object,
  setDeal: PropTypes.func,
  stepsArray: PropTypes.array,
  currentStep: PropTypes.object
};
