import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { FormInput } from "../../Shared/Forms";
import request from "../../../services/request";

export default function SalesforceIdForm({
  dealId,
  salesforceOpportunityId,
  setDeal
}) {
  const popup = useAlert();

  const [formVisible, setFormVisible] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      salesforceOpportunityId
    }
  });

  const updateSalesforceId = data => {
    request(Routes.update_metadata_deal_path(dealId), {
      body: data,
      method: "PUT"
    })
      .then(res => {
        setDeal(res);
        setFormVisible(false);
      })
      .catch(err =>
        err.errors.forEach(error => popup.show(error, { type: "error" }))
      );
  };

  return (
    <form onSubmit={handleSubmit(data => updateSalesforceId(data))}>
      {formVisible ? (
        <>
          <FormInput
            defaultValue={salesforceOpportunityId}
            style={{ width: "12.5rem" }}
            name="salesforce_opportunity_id"
            ref={register}
          />
          <br />
          {formState.isDirty && (
            <button type="submit" className="button text-button no-padding">
              Save
            </button>
          )}
          {!formState.isDirty && (
            <button
              type="button"
              className="button text-button no-padding"
              onClick={() => setFormVisible(false)}
            >
              Cancel
            </button>
          )}
        </>
      ) : (
        <>
          <p>{salesforceOpportunityId}</p>
          <a
            data-testid="edit-salesforce-id"
            role="button"
            tabIndex={0}
            onClick={() => setFormVisible(!formVisible)}
            onKeyDown={() => setFormVisible(!formVisible)}
          >
            Edit
          </a>
        </>
      )}
    </form>
  );
}

SalesforceIdForm.propTypes = {
  dealId: PropTypes.string,
  salesforceOpportunityId: PropTypes.string,
  setDeal: PropTypes.func
};
