import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Centered = styled.div`
  max-width: 72rem;
  margin: auto;
  text-align: center;
`;

const MainContent = styled.div`
  margin-top: 4rem;
  min-height: 50vh;

  h3 {
    text-align: left;
    font-weight: 500;
    font-size: 32px;
  }
`;

const Container = styled.div`
  justify-content: left;
  text-align: left;
`;

export default function StepContent(props) {
  const { step, children } = props;

  return (
    <Centered>
      <MainContent>
        <h3 style={{ margin: 0 }}>{step.title}</h3>
        <Container>{children}</Container>
      </MainContent>
    </Centered>
  );
}

StepContent.propTypes = {
  step: PropTypes.object
};
