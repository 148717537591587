import { render, within } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import { BrowserRouter as Router } from "react-router-dom";
import AlertTemplate from "../../Shared/AlertTemplate";
import ReviewStep from "./ReviewStep";
import {
  fullDealMock,
  mockWorkflowsWithFees
} from "../../../testHelpers/salesFlowMocks";

describe("The ReviewStep component", () => {
  it("renders without crashing", () => {
    const { container } = render(
      <AlertProvider template={AlertTemplate}>
        <Router>
          <ReviewStep deal={{}} />
        </Router>
      </AlertProvider>
    );
    expect(container);
  });

  it("displays the correct data given a deal object", () => {
    const { getByTestId } = render(
      <AlertProvider template={AlertTemplate}>
        <Router>
          <ReviewStep deal={fullDealMock} workflows={mockWorkflowsWithFees} />
        </Router>
      </AlertProvider>
    );
    const petitionerInformationDiv = getByTestId(
      "petitioner-information-section"
    );
    within(petitionerInformationDiv).getByText("Bigco");

    const beneficiaryAndVisaDiv = getByTestId("beneficiary-visas-section");

    within(beneficiaryAndVisaDiv).queryAllByText("John Deer");
    within(beneficiaryAndVisaDiv).getByText("E-2");
    within(beneficiaryAndVisaDiv).queryAllByText("jdeer@gmail.com");
  });
});
