import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { grayLighter } from "../../../styles/colors";

export default function ReviewSection(props) {
  const { header, children } = props;
  return (
    <section
      className="review-section"
      style={{ backgroundColor: grayLighter, marginTop: "3rem" }}
    >
      <h4
        className="review-section-title"
        style={{ backgroundColor: grayLighter }}
      >
        {header}
      </h4>
      {children}
    </section>
  );
}

ReviewSection.propTypes = {};
