import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import SectionsMenu from "../../PetitionPage/SectionsMenu";
import SectionsDropdowns from "../../Shared/SectionDropdowns/SectionDropdowns";
import { gray } from "../../../styles/colors";
import { formatCentsToCurrency } from "../../../utils/numberHelpers";
import PetitionBundleDetails from "./PetitionBundleDetails";
import CommonNav from "../../Shared/CommonNav/CommonNav";
import { formHeaders } from "../../../utils/form";
import SalesforceIdForm from "./SalesforceIdForm";
import QuickbooksIdForm from "./QuickbooksIdForm";

const StatusBox = styled.div`
  color: ${gray};
  text-align: center;
  width: 10rem;
  height: 8rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
`;
export default function DealShowPage(props) {
  const history = useHistory();
  const popup = useAlert();
  const { hash } = useLocation();

  const { deal: dealProp = {} } = props;

  const [deal, setDeal] = useState(dealProp);

  const {
    id: dealId,
    author,
    organization,
    billingPerson,
    petitionBundles,
    state,
    archived,
    salesforceOpportunityId,
    quickbooksInvoiceId
  } = deal;

  const [dealState, setDealState] = useState(state);

  const [showArchived, setShowArchived] = useState(archived);

  const handleArchive = async unarchive => {
    // Pass 'unarchive' or true to this function to unarchive
    try {
      const url = unarchive
        ? Routes.unarchive_deal_path(dealId)
        : Routes.archive_deal_path(dealId);

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify({})
      });

      if (!response.ok) {
        popup.show(response.error.message, { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setShowArchived(json.archived);
        const message = unarchive
          ? "Your deal has been unarchived"
          : "Your deal has been archived";
        popup.show(message, { type: "success" });
      }
    } catch (error) {
      popup.show("Something went wrong. Please contact Legalpad Engineering.", {
        type: "error"
      });
    }
  };

  const handleUnsubmit = async () => {
    try {
      const url = Routes.unsubmit_deal_path(dealId);

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify({})
      });

      if (!response.ok) {
        popup.show(response.error.message, { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setDealState(json.state);
        const message =
          "Your deal has been put back in draft state. Outstanding invoices have been voided";
        popup.show(message, { type: "success" });
      }
    } catch (error) {
      popup.show("Something went wrong. Please contact Legalpad Engineering.", {
        type: "error"
      });
    }
  };

  const renderStatusSection = () => {
    if (dealState === "draft" && showArchived) {
      return <p>This is a draft. </p>;
    }
    if (dealState === "draft" && !showArchived) {
      return (
        <p>
          This is a draft.{" "}
          <a
            role="link"
            tabIndex={0}
            onClick={() => history.push(`/deals/${dealId}/beneficiaries`)}
            onKeyDown={() => history.push(`/deals/${dealId}/beneficiaries`)}
          >
            Finalize and submit package.
          </a>
        </p>
      );
    }
    if (dealState === "active") {
      return (
        <StatusBox>
          <i className="fas fa-hand-holding-seedling fa-4x"></i>
          <p>Payments in progress</p>
        </StatusBox>
      );
    }

    return (
      <StatusBox>
        <i className="fas fa-hand-holding-usd fa-4x"></i>
        <p>Payment not yet received</p>
      </StatusBox>
    );
  };

  const renderDetailsSection = () => {
    if (dealState === "draft") {
      return (
        <p style={{ fontStyle: "italic" }}>
          Details will appear after the deal is submitted.
        </p>
      );
    }
    return (
      <>
        {petitionBundles?.map(bundle => (
          <PetitionBundleDetails petitionBundle={bundle} key={bundle.id} />
        ))}
      </>
    );
  };

  const renderActionsSection = () => (
    <>
      {dealState === "submitted" && (
        <p>
          <a
            tabIndex={0}
            role="button"
            className="warning"
            onKeyDown={() =>
              window.confirm("Are you sure you want to edit this deal?") &&
              handleUnsubmit()
            }
            onClick={() =>
              window.confirm("Are you sure you want to edit this deal?") &&
              handleUnsubmit()
            }
          >
            Edit deal
          </a>
        </p>
      )}
      {dealState !== "draft" && dealState !== "submitted" && (
        <p>No actions available</p>
      )}
      {!showArchived && dealState === "draft" && (
        <p>
          <a
            tabIndex={0}
            role="button"
            className="warning"
            onKeyDown={() =>
              window.confirm("Are you sure you want to archive this deal?") &&
              handleArchive()
            }
            onClick={() =>
              window.confirm("Are you sure you want to archive this deal?") &&
              handleArchive()
            }
          >
            Archive this deal
          </a>
        </p>
      )}
      {showArchived && dealState === "draft" && (
        <p>
          <a
            tabIndex={0}
            role="button"
            className="warning"
            onKeyDown={() => handleArchive("unarchive")}
            onClick={() => handleArchive("unarchive")}
          >
            Unarchive this deal
          </a>
        </p>
      )}
    </>
  );

  const sections = [
    { name: "Status", content: renderStatusSection() },
    { name: "Details", content: renderDetailsSection() },
    { name: "Actions", content: renderActionsSection() }
  ];

  // Scroll offset hack for hashlink routing adjustment
  useEffect(() => {
    if (hash === "#status")
      return document.getElementById("sales-container")?.scrollBy(0, -70);
  }, [hash]);

  return (
    <div className="new-ui left-nav-page-wrapper non-intake main-content fixed-header gray-background ">
      <CommonNav
        home={{ text: "Deals", href: "/sales/deals" }}
        title={{
          text: `${deal.organization?.displayName}`,
          href: `/sales/deals/${deal.id}`
        }}
      />
      <nav>
        <div className="nav-content-wrapper" style={{ marginTop: "3rem" }}>
          <SectionsMenu sections={sections} />
        </div>
      </nav>
      <main>
        <h2>{organization?.displayName}</h2>
        <br />
        <div
          style={{
            display: "flex",
            gap: "3rem",
            width: "fit-content"
          }}
        >
          <div>
            <h6>Account Executive</h6>
            <p>{author?.fullName}</p>
          </div>
          {deal.submittedAt && (
            <div>
              <h6>Deal Submitted</h6>
              {moment(deal.submittedAt).format("MMMM DD, YYYY")}
            </div>
          )}
        </div>
        <SectionsDropdowns sections={sections} />{" "}
      </main>
    </div>
  );
}

DealShowPage.propTypes = {
  deal: PropTypes.object
};
