import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { stringifyNumber } from "../../../utils/numberHelpers";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect";
import { formHeaders } from "../../../utils/form";
import AdditionalServiceSelect from "./AdditionalServiceSelect/AdditionalServiceSelect";
import { countOccurrences } from "../../../utils/arrayMethods";

const Container = styled.div`
  margin-top: 1.75rem;
`;

const BundleHeader = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  h5 {
    margin: 0 1rem 0 0;
  }
  &&& {
    a {
      text-decoration: none;
      margin: 0;
    }
  }
`;

export default function BundleOptions(props) {
  const popup = useAlert();

  const {
    bundle = null,
    index = 1,
    newBeneficiaryBundles,
    setNewBeneficiaryBundles,
    beneficiary,
    workflows = [],
    currentBundles,
    setCurrentBundles,
    createBundle
  } = props;

  const selectedWorkflowIdsForBeneficiary = currentBundles
    .filter(bun => bun.beneficiary.id === beneficiary.id)
    .map(bun => bun.workflowId);

  const allWorkflowOptions = workflows.map(wf => ({
    value: wf.id,
    label: wf.name
  }));

  const availableWorkflowOptionsForBundle = allWorkflowOptions.filter(
    wf => !selectedWorkflowIdsForBeneficiary.includes(wf.value)
  );

  const bundleActionTypes = {
    updateBeneficiary: "UPDATE_BENEFICIARY",
    updateWorkflow: "UPDATE_WORKFLOW",
    updateAdditionalServices: "UPDATE_ADDITIONAL_SERVICES"
  };

  const bundleReducer = (currentState, action) => {
    switch (action.type) {
      case bundleActionTypes.updateBeneficiary: {
        return { ...currentState, beneficiary: action.value };
      }
      case bundleActionTypes.updateWorkflow: {
        return {
          ...currentState,
          workflowId: action.value
        };
      }
      case bundleActionTypes.updateAdditionalServices: {
        return {
          ...currentState,
          additionalServiceIds: action.value
        };
      }
      default: {
        return currentState;
      }
    }
  };

  const [petitionBundle, dispatch] = useReducer(
    bundleReducer,
    bundle ?? createBundle({ beneficiary })
  );

  const bundleOrdinal = `${stringifyNumber(index)
    .charAt(0)
    .toUpperCase() + stringifyNumber(index).slice(1)} Visa`;

  const removeBundle = async () => {
    if (petitionBundle.id) {
      try {
        // eslint-disable-next-line no-undef
        const url = Routes.petition_bundle_path(petitionBundle.id);

        const response = await fetch(url, {
          method: "DELETE",
          headers: formHeaders()
        });

        if (!response.ok) {
          popup.show("Something went wrong", { type: "error" });
        }

        if (response.ok) {
          const newBundles = currentBundles.filter(
            cb => cb.id !== petitionBundle.id
          );
          setCurrentBundles(newBundles);
        }
      } catch (error) {
        popup.show(error.message, { type: "error" });
      }
    } else {
      const newBundles = currentBundles.filter(
        cb => cb.tempId !== petitionBundle.tempId
      );
      setCurrentBundles(newBundles);

      if (newBeneficiaryBundles) {
        const updatedNewBeneficiaryBundles = newBeneficiaryBundles.filter(
          nbb => nbb.tempId !== petitionBundle.tempId
        );
        setNewBeneficiaryBundles(updatedNewBeneficiaryBundles);
      }
    }
  };

  const defaultOption = allWorkflowOptions.find(
    option => option.value === petitionBundle.workflowId
  );

  const defaultWorkflow = workflows.find(
    wf => wf.id === petitionBundle.workflowId
  );

  const [selectedWorkflow, setSelectedWorkflow] = useState(defaultWorkflow);

  const handleWorkflowChange = selectedWf => {
    dispatch({
      type: "UPDATE_WORKFLOW",
      value: selectedWf.value
    });
    const newWorkflow = workflows.find(wf => wf.id === selectedWf.value);
    setSelectedWorkflow(newWorkflow);

    const defaultAdditionalServiceIds = newWorkflow.additionalServices
      .filter(service => service.isDefaultOption)
      .map(service => service.id);

    dispatch({
      type: "UPDATE_ADDITIONAL_SERVICES",
      value: defaultAdditionalServiceIds
    });
  };

  const handleAdditionalServicesChange = (serviceId, quantity) => {
    dispatch({
      type: "UPDATE_ADDITIONAL_SERVICES",
      value: [
        ...petitionBundle.additionalServiceIds.filter(ao => ao !== serviceId),
        ...Array(quantity).fill(serviceId)
      ]
    });
  };

  // Update the beneficiary fields if they change
  useEffect(() => {
    dispatch({
      type: "UPDATE_BENEFICIARY",
      value: beneficiary
    });
  }, [beneficiary]);

  // Update the current bundles array by ID any time the bundle changes
  useDidMountEffect(() => {
    const updateBundles = () => {
      const updatedBundles = currentBundles.map(b => {
        if (b.tempId && b.tempId === petitionBundle.tempId) {
          return petitionBundle;
        }
        if (b.id && b.id === petitionBundle.id) {
          return petitionBundle;
        }
        return b;
      });

      setCurrentBundles(updatedBundles);
    };
    updateBundles();
  }, [
    petitionBundle.beneficiary,
    petitionBundle.workflowId,
    petitionBundle.additionalServiceIds
  ]);

  return (
    <Container className="bundle-options">
      <BundleHeader>
        <h5>{bundleOrdinal}</h5>
        {(selectedWorkflow || index > 1) && (
          <a
            role="button"
            tabIndex="0"
            onClick={() => removeBundle()}
            onKeyDown={() => removeBundle()}
          >
            REMOVE
          </a>
        )}
      </BundleHeader>
      <div style={{ width: "12rem" }} data-testid="workflow-select">
        <Select
          value={defaultOption}
          options={availableWorkflowOptionsForBundle}
          className="visa-type-select"
          classNamePrefix="visa-type"
          placeholder="Select visa type..."
          onChange={handleWorkflowChange}
        />
      </div>
    </Container>
  );
}

BundleOptions.propTypes = {
  beneficiary: PropTypes.object,
  workflows: PropTypes.array
};
