import React, { useState } from "react";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { grayLighter } from "../../styles/colors";
import DataProvider from "./DataProvider";

const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

export default function SalesFlow() {
  document.body.style.backgroundColor = grayLighter;

  const baseUrl = "/sales";

  // Current server state
  const [deal, setDeal] = useState({});

  return (
    <Container>
      <Router basename={baseUrl}>
        <DataProvider deal={deal} setDeal={setDeal} />
      </Router>
    </Container>
  );
}
