import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { formHeaders } from "../../../utils/form";
import { IndexRow } from "../../Shared/IndexRow/IndexRow";
import AdditionalServiceRow from "./AdditionalServiceRow";
import { blue } from "../../../styles/colors";
import { CheckboxItem } from "../../Shared/CheckBox";
import StatusTag from "../../Shared/StatusTag/StatusTag";
import { useAuthContext } from "../../Shared/ContextProviders/AuthProvider";

const FormInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${blue};
  border-radius: 4px;
  width: ${props => props.width};
`;

export default function ServicesPage() {
  const popup = useAlert();

  const user = useAuthContext();
  const hasEngAccess = user.engAccess;

  const [additionalServices, setAdditionalServices] = useState([]);
  const [asFormVisible, setAsFormVisible] = useState(false);
  const [additionalServiceFormState, setAdditionalServiceFormState] = useState({
    name: "",
    amount: "",
    maxQuantity: 1,
    isDefaultOption: false
  });

  useEffect(() => {
    const fetchAdditionalServices = async () => {
      try {
        const url = Routes.additional_services_path();

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          popup.show(
            `Something went wrong: \n ${response.status} ${response.statusText}`,
            { type: "error" }
          );
        }

        if (response.ok) {
          const json = await response.json();
          setAdditionalServices(json.additional_services);
        }
      } catch (error) {
        popup.show(error.message, { type: "error" });
      }
    };
    fetchAdditionalServices();
  }, []);

  const handleAdditionalServiceFormChange = (e, isCheckbox) => {
    if (isCheckbox) {
      setAdditionalServiceFormState({
        ...additionalServiceFormState,
        [e.target.name]: e.target.checked
      });
    } else {
      setAdditionalServiceFormState({
        ...additionalServiceFormState,
        [e.target.name]: e.target.value
      });
    }
  };

  const createAdditionalService = async () => {
    try {
      const url = Routes.additional_services_path();

      const requestBody = {
        additional_service: {
          name: additionalServiceFormState.name,
          amount: parseInt(additionalServiceFormState.amount * 100),
          is_default_option: additionalServiceFormState.isDefaultOption,
          max_quantity: additionalServiceFormState.maxQuantity
        }
      };

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const json = await response.json();
        popup.show(
          `Something went wrong: \n ${response.status} ${response.statusText} \n ${json}`,
          { type: "error" }
        );
      }

      if (response.ok) {
        const json = await response.json();
        setAdditionalServices([...additionalServices, json]);
        setAsFormVisible(false);
        setAdditionalServiceFormState({
          name: "",
          amount: "",
          maxQuantity: 1,
          isDefaultOption: false
        });
        popup.show("Service successfully created", { type: "success" });
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    }
  };

  return (
    <div style={{ margin: "5rem 3rem" }}>
      <section style={{ marginTop: "3rem" }}>
        <h1>Additional Services</h1>
        {!hasEngAccess && (
          <StatusTag text="You have read-only permission to this page. Contact engineering for more information." />
        )}
        <table style={{ display: "grid" }}>
          <tbody>
            <IndexRow>
              <th>Name</th>
              <th>Price</th>
              <th>Max quantity</th>
              <th>Default selected</th>
              <th />
              <th />
            </IndexRow>
            {additionalServices.map(as => (
              <AdditionalServiceRow
                key={as.id}
                additionalService={as}
                setAdditionalServices={setAdditionalServices}
                hasEngAccess={hasEngAccess}
              />
            ))}
            {asFormVisible && (
              <IndexRow data-testid="additional-service-form">
                <td>
                  <FormInput
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={additionalServiceFormState.name}
                    onChange={e => handleAdditionalServiceFormChange(e)}
                  />
                </td>

                <td>
                  $
                  <FormInput
                    name="amount"
                    type="text"
                    width="6rem"
                    placeholder="Price"
                    value={additionalServiceFormState.amount}
                    onChange={e => handleAdditionalServiceFormChange(e)}
                  />
                </td>
                <td>
                  <FormInput
                    name="maxQuantity"
                    type="number"
                    width="4rem"
                    placeholder="Maximum quantity"
                    value={additionalServiceFormState.maxQuantity}
                    onChange={e => handleAdditionalServiceFormChange(e)}
                  />
                </td>
                <td>
                  <CheckboxItem>
                    <input
                      name="isDefaultOption"
                      type="checkbox"
                      defaultChecked={additionalServiceFormState.name}
                      onChange={e => handleAdditionalServiceFormChange(e, true)}
                    />
                    <label>Default option</label>
                  </CheckboxItem>
                </td>
                <td />
                <td className="actions">
                  <button
                    type="button"
                    className="small cancel u_margin-right-1rem"
                    onClick={() => setAsFormVisible(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="small"
                    onClick={() => createAdditionalService()}
                  >
                    Save
                  </button>
                </td>
              </IndexRow>
            )}
          </tbody>
        </table>
        {!asFormVisible && (
          <button
            type="button"
            className={`text-button small secondary ${
              !hasEngAccess ? "disabled" : ""
            }`}
            tabIndex="0"
            disabled={!hasEngAccess}
            onClick={() => setAsFormVisible(true)}
          >
            New service
          </button>
        )}
      </section>
    </div>
  );
}
