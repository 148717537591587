import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IndexRow } from "../../Shared/IndexRow/IndexRow";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect";

const Input = styled.input`
  border: none;
  width: ${props => props.width ?? "auto"};
  cursor: ${props => (props.disabled ? "not-allowed" : "auto")};
`;

export default function BaseFeeRow(props) {
  const {
    baseFee,
    setCurrentBaseFees,
    setUpdateBaseFees,
    hasEngAccess
  } = props;
  const [currentBaseFee, setCurrentBaseFee] = useState({
    ...baseFee,
    amount: baseFee.amount
  });

  const deleteBaseFee = async () => {
    setCurrentBaseFees(prevFees =>
      prevFees.filter(fee => fee.tempId !== currentBaseFee.tempId)
    );
    setUpdateBaseFees(true);
  };

  const handleDelete = () =>
    // eslint-disable-next-line no-restricted-globals
    confirm(
      "Are you sure you want to delete this service? This cannot be undone."
    ) && deleteBaseFee();

  const handleBaseFeeChange = e => {
    if (e.target.name === "amount") {
      setCurrentBaseFee({
        ...currentBaseFee,
        [e.target.name]: e.target.value * 100
      });
    } else {
      setCurrentBaseFee({
        ...currentBaseFee,
        [e.target.name]: e.target.value
      });
    }
  };

  useDidMountEffect(
    () =>
      setCurrentBaseFees(prevFees =>
        prevFees.map(fee =>
          fee.tempId === currentBaseFee.tempId ? currentBaseFee : fee
        )
      ),
    [currentBaseFee]
  );

  return (
    <IndexRow columns={3} data-testid="base-fee-row">
      <td>
        <Input
          name="name"
          value={currentBaseFee.name}
          onChange={e => handleBaseFeeChange(e)}
          style={{ fontWeight: 600 }}
          disabled={!hasEngAccess}
        />
      </td>
      <td
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        $
        <Input
          name="amount"
          width="6rem"
          type="number"
          defaultValue={currentBaseFee.amount / 100}
          onChange={e => handleBaseFeeChange(e)}
          disabled={!hasEngAccess}
        />
      </td>
      <td className="actions">
        <button
          type="button"
          className="button text-button no-padding destructive"
          onClick={() => handleDelete()}
          onKeyDown={() => handleDelete()}
          disabled={!hasEngAccess}
          style={{ cursor: !hasEngAccess ? "not-allowed" : "pointer" }}
        >
          DELETE
        </button>
      </td>
    </IndexRow>
  );
}

BaseFeeRow.propTypes = {
  baseFee: PropTypes.object
};
