import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { formHeaders } from "../../../utils/form";
import WorkflowDetails from "./WorkflowDetails";
import StatusTag from "../../Shared/StatusTag/StatusTag";
import { useAuthContext } from "../../Shared/ContextProviders/AuthProvider";

export default function WorkflowsPage() {
  const popup = useAlert();
  const [workflows, setWorkflows] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);

  const user = useAuthContext();
  const hasEngAccess = user.engAccess;

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const url = Routes.workflows_path();

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          popup.show(
            `Something went wrong: \n ${response.status} ${response.statusText}`,
            { type: "error" }
          );
        }

        if (response.ok) {
          const json = await response.json();
          setWorkflows(json.items);
        }
      } catch (error) {
        popup.show(error.message, { type: "error" });
      }
    };
    const fetchAdditionalServices = async () => {
      try {
        const url = Routes.additional_services_path();

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          popup.show(
            `Something went wrong: \n ${response.status} ${response.statusText}`,
            { type: "error" }
          );
        }

        if (response.ok) {
          const json = await response.json();
          setAdditionalServices(json.additional_services);
        }
      } catch (error) {
        popup.show(error.message, { type: "error" });
      }
    };
    fetchWorkflows();
    fetchAdditionalServices();
  }, []);

  return (
    <div style={{ margin: "5rem 3rem", maxWidth: "68rem" }}>
      <section style={{ marginTop: "3rem" }}>
        <h1>Workflows</h1>
        {!hasEngAccess && (
          <StatusTag text="You have read-only permission to this page. Contact engineering for more information." />
        )}
        {workflows.map(wf => (
          <WorkflowDetails
            key={wf.id}
            workflow={wf}
            additionalServices={additionalServices}
            hasEngAccess={hasEngAccess}
          />
        ))}
      </section>
    </div>
  );
}
