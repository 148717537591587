import { render } from "@testing-library/react";
import RunningTotal from "./RunningTotal";
import {
  mockWorkflowsWithFees,
  mockPetitionBundles
} from "../../../testHelpers/salesFlowMocks";

describe("The RunningTotal component", () => {
  it("renders without crashing", () => {
    const { container } = render(<RunningTotal />);
    expect(container);
  });

  it("displays the correct information when given an array of petition bundles", () => {
    const { getByText } = render(
      <RunningTotal
        petitionBundles={mockPetitionBundles}
        workflows={mockWorkflowsWithFees}
      />
    );
    expect(getByText("EB-1A"));
    expect(getByText("$10,700"));

    expect(getByText("H-1B"));
    expect(getByText("$7,760"));
    expect(getByText("Guaranteed approval"));
    expect(getByText("$1,500"));
    expect(getByText("Additional press"));
    expect(getByText("$750"));

    expect(getByText("Pay Total"));
    expect(getByText("$18,460"));
  });
});
