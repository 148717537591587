import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import SectionsDropdowns from "../../Shared/SectionDropdowns/SectionDropdowns";
import DealsTable from "./DealsTable/DealsTable";
import { formHeaders } from "../../../utils/form";
import SideNav from "../../DealIndexPage/SideNav/SideNav";
import SearchBar from "../../Shared/SearchBar/SearchBar";

export default function DealIndexPage() {
  const popup = useAlert();
  const [deals, setDeals] = useState([]);

  const fetchDeals = async () => {
    try {
      // eslint-disable-next-line no-undef
      const url = Routes.deals_path();

      const response = await fetch(url, {
        method: "GET",
        headers: formHeaders()
      });

      if (!response.ok) {
        popup.show("Something went wrong", { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setDeals(json.deals);
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    }
  };

  useEffect(() => {
    fetchDeals();
  }, []);

  const [caseState, setCaseState] = useState({
    draftDeals: [],
    paymentPendingDeals: [],
    paymentProgressDeals: [],
    paidDeals: [],
    archivedDeals: []
  });

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const filteredDeals = deals
      ? deals.filter(deal => {
          const searchables = [
            deal.organization.displayName,
            deal.billingPerson.fullName,
            deal.dealBeneficiaries,
            deal.author.fullName
          ]
            .toString()
            .toLowerCase();

          return searchables.includes(searchString.trim().toLowerCase());
        })
      : [];

    const archivedDeals = filteredDeals.filter(deal => deal.archived === true);
    const liveDeals = filteredDeals.filter(deal => deal.archived === false);
    setCaseState({
      archivedDeals,
      draftDeals: liveDeals.filter(deal => deal.state === "draft"),
      paymentPendingDeals: liveDeals.filter(deal => deal.state === "submitted"),
      paymentProgressDeals: liveDeals.filter(deal => deal.state === "active"),
      paidDeals: liveDeals.filter(deal => deal.state === "complete")
    });
  }, [searchString, deals]);

  const sections = [
    {
      name: "Drafts",
      content: (
        <DealsTable
          groupName="Draft Deals"
          dealGroup={caseState.draftDeals}
          setDealsState={setDeals}
          searchString={searchString}
          maxPerPage={10}
        />
      )
    },
    {
      name: "Payment Pending",
      content: (
        <DealsTable
          groupName="Pending Deals"
          dealGroup={caseState.paymentPendingDeals}
          setDealsState={setDeals}
          maxPerPage={10}
        />
      )
    },
    {
      name: "Payment in Progress",
      content: (
        <DealsTable
          groupName="Payment In Progress Deals"
          dealGroup={caseState.paymentProgressDeals}
          setDealsState={setDeals}
          maxPerPage={10}
        />
      )
    },
    {
      name: "Fully Paid",
      content: (
        <DealsTable
          groupName="Paid Deals"
          dealGroup={caseState.paidDeals}
          setDealsState={setDeals}
          maxPerPage={10}
        />
      )
    },
    {
      name: "Archived",
      content: (
        <DealsTable
          groupName="Archived Deals"
          dealGroup={caseState.archivedDeals}
          setDealsState={setDeals}
          maxPerPage={10}
        />
      )
    }
  ];

  return (
    <div
      className="new-ui left-nav-page-wrapper index-page non-intake main-content fixed-header gray-background "
      style={{ marginTop: "-1rem" }}
    >
      <SideNav sections={sections} />
      <main>
        <SearchBar
          id="deal-index-searchbar"
          dataTestId="deal-index-searchbar"
          placeholder="Search by petitioner, bill contact, or account executive"
          searchStringValue={searchString}
          setSearchStringEvent={setSearchString}
        />
        <SectionsDropdowns sections={sections} />
      </main>
    </div>
  );
}
