import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { grayDark } from "../../../styles/colors";
import { formHeaders } from "../../../utils/form";
import ActionFrame from "../ActionFrame";
import AutoSuggest from "../../Shared/AutoSuggest/AutoSuggest";
import { CheckboxItem } from "../../Shared/CheckBox";

const Section = styled.div`
  margin-bottom: 2rem;
  h4 {
    margin: 1rem 0 0 0;
  }

  p {
    color: ${grayDark};
  }

  a {
    text-decoration: underline;
    margin: 0.5rem 0;
  }
`;

export default function PetitionerStep(props) {
  const {
    stepsArray,
    currentStep,
    organizationId,
    setOrganizationId,
    petitionerItems,
    deal,
    setDeal
  } = props;

  const { organization, id: dealId } = deal;

  const popup = useAlert();
  const history = useHistory();

  const [errors, setErrors] = useState([]);

  const [organizationName, setOrganizationName] = useState(
    organization?.displayName
  );
  const [isSelfPetitioning, setIsSelfPetitioning] = useState(
    organization?.isSelfPetitioning ?? false
  );
  const [isNotYetEstablished, setIsNotYetEstablished] = useState(
    organization?.isNotYetEstablished ?? false
  );

  const [loading, setLoading] = useState(false);

  const createOrUpdateDeal = async () => {
    try {
      setLoading(true);

      const url = dealId ? Routes.deal_path(dealId) : Routes.deals_path();

      let requestBody;
      const method = dealId ? "PUT" : "POST";

      // eslint-disable-next-line default-case
      switch (true) {
        case !!organizationId:
          requestBody = {
            organization_id: organizationId
          };
          break;
        case !organizationId:
          requestBody = {
            organization_attributes: {
              display_name: organizationName,
              is_self_petitioning: isSelfPetitioning,
              is_not_yet_established: isNotYetEstablished
            }
          };
          break;
      }

      const response = await fetch(url, {
        method,
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const json = await response.json();
        setLoading(false);
        setErrors([...errors, json.errors]);
        json.errors.forEach(err => {
          setErrors([...errors, err]);
          popup.show(err, { type: "error" });
        });
      }

      if (response.ok) {
        const json = await response.json();
        setDeal(json);
        setLoading(false);
        history.push(`/deals/${json.id}/beneficiaries`);
        popup.show("Draft deal has been saved!", {
          type: "success",
          timeout: 1500
        });
      }
    } catch (error) {
      setLoading(false);
      popup.show(error.message, { type: "error" });
    }
  };

  useEffect(() => {
    if (isSelfPetitioning) {
      setOrganizationName("Self-petitioning");
      if (!deal.organization) setOrganizationId(null);
    }
    if (isNotYetEstablished) {
      setOrganizationName("Not yet established");
      if (!deal.organization) setOrganizationId(null);
    }
    if (isSelfPetitioning && isNotYetEstablished) {
      setOrganizationName("Self-petitioning/not yet established");
    }
  }, [isSelfPetitioning, isNotYetEstablished]);

  const petitionerComplete =
    organizationId ||
    organizationName ||
    isSelfPetitioning ||
    isNotYetEstablished;
  const disableContinue = !petitionerComplete;

  return (
    <>
      <Section>
        <h4>Petitioner</h4>
        <div className="text-input-wrapper">
          {!isSelfPetitioning && !isNotYetEstablished && (
            <AutoSuggest
              label="Petitioner"
              placeholder="Choose an organization or enter a new one..."
              initialValue={organizationName}
              items={petitionerItems}
              handleSelect={selected => setOrganizationId(selected.id)}
              handleInputChange={value => setOrganizationName(value)}
            />
          )}
        </div>
        <div style={{ marginTop: "1rem" }}>
          <CheckboxItem>
            <input
              id="is-self-petitioning"
              type="checkbox"
              checked={isSelfPetitioning}
              onChange={() => setIsSelfPetitioning(!isSelfPetitioning)}
            />
            <label htmlFor="is-self-petitioning">
              Client is self-petitioning
            </label>
          </CheckboxItem>
          <CheckboxItem>
            <input
              id="is-not-yet-established"
              type="checkbox"
              checked={isNotYetEstablished}
              onChange={() => setIsNotYetEstablished(!isNotYetEstablished)}
            />
            <label htmlFor="is-not-yet-established">
              Company not yet established
            </label>
          </CheckboxItem>
        </div>
      </Section>

      <ActionFrame
        loading={loading}
        continueDisabled={disableContinue}
        stepsArray={stepsArray}
        currentStep={currentStep}
        handleContinue={() => createOrUpdateDeal()}
        hasErrors={errors.length > 0}
      />
    </>
  );
}
