import React, { useState } from "react";
import PropTypes, { object } from "prop-types";
import styled from "styled-components";
import Collapse from "react-css-collapse";
import { formatCentsToCurrency } from "../../../utils/numberHelpers";
import { grayDark, grayDarker, white, grayLight } from "../../../styles/colors";

const DiscountsBox = styled.div`
  margin-top: 3rem;
  width: 23rem;
  background: ${white};
  border: 1px solid ${grayLight};
  border-radius: 6px;
  padding: 1rem;
  p {
    margin: 0;
  }
`;

const DiscountRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  color: ${grayDark};
  p {
    font-size: 14px;
  }
`;

export default function DiscountsDetails(props) {
  const { discountsTotal, dealDiscounts } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <DiscountsBox>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <a
          style={{
            color: grayDarker,
            outline: "none",
            display: "inline-flex",
            alignItems: "center"
          }}
          role="button"
          tabIndex={0}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          onKeyDown={() => setDropdownOpen(!dropdownOpen)}
        >
          <i
            title="arrow"
            style={{ marginRight: "1rem" }}
            className={
              dropdownOpen
                ? "u_color-blue far fa-chevron-down"
                : "u_color-blue far fa-chevron-right"
            }
          />
          <p>Total Discounts</p>
        </a>
        <p>-{formatCentsToCurrency(discountsTotal)}</p>
      </div>
      <Collapse data-testid="css-collapse" isOpen={dropdownOpen}>
        {dealDiscounts.map((discount, i) => (
          <DiscountRow key={i}>
            <p>{discount.name}</p>
            <p>-{formatCentsToCurrency(discount.amount)}</p>
          </DiscountRow>
        ))}
      </Collapse>
    </DiscountsBox>
  );
}

DiscountsDetails.propTypes = {
  discountsTotal: PropTypes.number,
  dealDiscounts: PropTypes.arrayOf(object)
};
