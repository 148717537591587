import React from "react";
import PropTypes from "prop-types";

export default function SelectBox(props) {
  const { contact, handleChange, checked, disabled, name } = props;
  return (
    <div className="checkbox-wrapper" style={{ width: "21rem" }}>
      <label className="input-button-label">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          name={name}
        />
        <div className="checkbox">
          {contact.firstName} {contact.lastName}{" "}
          <p style={{ marginBottom: 0 }}>{contact.email}</p>
        </div>
      </label>
    </div>
  );
}

SelectBox.propTypes = {
  contact: PropTypes.object
};
