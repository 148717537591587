import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import CommonNav from "../../Shared/CommonNav/CommonNav";
import ServicesPage from "../ServicesPage/ServicesPage";
import WorkflowsPage from "../WorkflowsPage/WorkflowsPage";
import DealIndexPage from "../DealIndexPage/DealIndexPage";

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
`;

export default function SalesDashboard() {
  return (
    <Layout>
      <CommonNav home={{ text: "Sales", href: "/sales" }} useBrowserRouter />
      <div style={{ overflow: "auto" }}>
        <Switch>
          <Route path="/services">
            <ServicesPage />
          </Route>
          <Route path="/workflows">
            <WorkflowsPage />
          </Route>
          <Route path="/deals">
            <DealIndexPage />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
}
