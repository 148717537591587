import { render, waitFor } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import { BrowserRouter as Router } from "react-router-dom";
import DealShowPage from "./DealShowPage";
import { mockDeals } from "../../../testHelpers/dealIndexMocks";
import { mockUser } from "../../../testHelpers/userMocks";
import AlertTemplate from "../../Shared/AlertTemplate";

describe("The DealShowPage component", () => {
  // fetch deals call on render
  beforeEach(() => {
    fetch.mockResponses([JSON.stringify(mockUser), { status: 200 }]);
  });

  afterEach(() => fetch.resetMocks());

  it("renders without crashing", async () => {
    const { container, getByText } = render(
      <AlertProvider template={AlertTemplate}>
        <Router>
          <DealShowPage deal={mockDeals[0]} />
        </Router>
      </AlertProvider>
    );

    expect(fetch.mock.calls.length).toEqual(1);
    expect(container);
  });

  describe("actions and status", () => {
    it("displays a the finalize and submit link for a draft deal state", () => {
      const deal = mockDeals[2];
      deal.state = "draft";

      const { getByText, queryByText } = render(
        <AlertProvider template={AlertTemplate}>
          <Router>
            <DealShowPage deal={deal} />
          </Router>
        </AlertProvider>
      );

      expect(getByText("This is a draft."));
      expect(getByText("Finalize and submit package."));
      expect(getByText("Archive this deal"));
      expect(queryByText("No actions available")).not.toBeInTheDocument();
    });

    it("displays the unarchive link for an archived deal", () => {
      const deal = mockDeals[2];
      deal.state = "draft";
      deal.archived = true;

      const { getByText } = render(
        <AlertProvider template={AlertTemplate}>
          <Router>
            <DealShowPage deal={deal} />
          </Router>
        </AlertProvider>
      );

      expect(getByText("Unarchive this deal"));
    });

    it("displays the status and edit link for the submitted deal state", () => {
      const deal = mockDeals[2];
      deal.state = "submitted";
      deal.archived = false;

      const { getByText, queryByText } = render(
        <AlertProvider template={AlertTemplate}>
          <Router>
            <DealShowPage deal={deal} />
          </Router>
        </AlertProvider>
      );

      expect(getByText("Payment not yet received"));
      expect(getByText("Edit deal"));
      expect(queryByText("No actions available")).not.toBeInTheDocument();
    });

    it("displays a friendly name for the active deal state", () => {
      const deal = mockDeals[2];
      deal.state = "active";
      const { getByText, queryByText } = render(
        <AlertProvider template={AlertTemplate}>
          <Router>
            <DealShowPage deal={deal} />
          </Router>
        </AlertProvider>
      );

      expect(queryByText("Edit deal")).not.toBeInTheDocument();
      expect(getByText("Payments in progress"));
      expect(queryByText("No actions available"));
    });
  });
});
