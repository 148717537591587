import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { FormInput } from "../../Shared/Forms";
import request from "../../../services/request";
import { red } from "../../../styles/colors";

export default function QuickbooksIdForm({
  dealId,
  quickbooksInvoiceId,
  setDeal
}) {
  const popup = useAlert();

  const [formVisible, setFormVisible] = useState(false);

  const { register, handleSubmit, setValue, formState, errors } = useForm({
    defaultValues: {
      quickbooksInvoiceId
    }
  });

  const updateQuickbooksId = data => {
    if (formState.isDirty)
      request(Routes.update_metadata_deal_path(dealId), {
        body: data,
        method: "PUT"
      })
        .then(res => {
          setDeal(res);
          setFormVisible(false);
        })
        .catch(err =>
          err.errors.forEach(error => popup.show(error, { type: "error" }))
        );
  };

  const handlePaste = e => {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    paste = paste.replace(/\D/g, "");
    setValue("quickbooks_invoice_id", paste, {
      shouldValidate: true,
      shouldDirty: true
    });
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(data => updateQuickbooksId(data))}>
      {formVisible ? (
        <>
          <div style={{ display: "inline-block" }}>
            <strong>INV-</strong>
            <FormInput
              defaultValue={quickbooksInvoiceId}
              style={{ width: "8.5rem" }}
              name="quickbooks_invoice_id"
              onPaste={e => handlePaste(e)}
              ref={register({ required: true, minLength: 4, maxLength: 5 })}
              aria-invalid={errors.quickbooks_invoice_id ? "true" : "false"}
              error={errors.quickbooks_invoice_id}
            />
          </div>
          <br />
          {errors.quickbooks_invoice_id?.type === "required" && (
            <small style={{ color: red }} role="alert">
              This is required
            </small>
          )}
          {errors.quickbooks_invoice_id?.type === "maxLength" && (
            <small style={{ color: red }} role="alert">
              Max length exceeded
            </small>
          )}
          {errors.quickbooks_invoice_id?.type === "minLength" && (
            <small style={{ color: red }} role="alert">
              Minimum length 4 characters
            </small>
          )}
          {errors.quickbooks_invoice_id && <br />}
          {formState.isDirty && (
            <button type="submit" className="button text-button no-padding">
              Save
            </button>
          )}
          {!formState.isDirty && (
            <button
              type="button"
              className="button text-button no-padding"
              onClick={() => setFormVisible(false)}
            >
              Cancel
            </button>
          )}
        </>
      ) : (
        <>
          <p>INV-{quickbooksInvoiceId}</p>
          <a
            data-testid="edit-quickbooks-id"
            role="button"
            tabIndex={0}
            onClick={() => setFormVisible(!formVisible)}
            onKeyDown={() => setFormVisible(!formVisible)}
          >
            Edit
          </a>
        </>
      )}
    </form>
  );
}

QuickbooksIdForm.propTypes = {
  dealId: PropTypes.string,
  quickbooksInvoiceId: PropTypes.string,
  setDeal: PropTypes.func
};
