import { render, waitFor, fireEvent } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import { BrowserRouter as Router } from "react-router-dom";
import BeneficiariesStep from "./BeneficiariesStep";
import AlertTemplate from "../../Shared/AlertTemplate";
import {
  mockOrganizationPeople,
  mockWorkflowsWithFees
} from "../../../testHelpers/salesFlowMocks";
import { FormContextProvider } from "../FormStateProvider";

describe("The BeneficiariesStep component", () => {
  it("renders without crashing and does not make a fetch on initial render", async () => {
    const { container, getByText } = render(
      // need to wrap with the alert provider for popups
      <AlertProvider template={AlertTemplate}>
        <Router>
          <FormContextProvider>
            <BeneficiariesStep deal={{}} />
          </FormContextProvider>
        </Router>
      </AlertProvider>
    );
    expect(fetch.mock.calls.length).toEqual(0);
    await waitFor(() => {
      expect(container);
      expect(getByText("Choose beneficiaries"));
    });
  });
  it("allows the user to select from a list or organization people", async () => {
    const { getByText } = render(
      // need to wrap with the alert provider for popups
      <AlertProvider template={AlertTemplate}>
        <Router>
          <FormContextProvider>
            <BeneficiariesStep
              deal={{}}
              organizationPeople={mockOrganizationPeople}
            />
          </FormContextProvider>
        </Router>
      </AlertProvider>
    );
    await waitFor(() => {
      expect(getByText("Homer Simpson"));
      expect(getByText("Barney Gumble"));
    });
    fireEvent.click(getByText("Homer Simpson"));
    expect(getByText("First Visa"));
  });
  it("does not let the user continue until there is a valid bundle", async () => {
    const { getByText, getByTestId } = render(
      // need to wrap with the alert provider for popups
      <AlertProvider template={AlertTemplate}>
        <Router>
          <FormContextProvider>
            <BeneficiariesStep
              deal={{}}
              organizationPeople={mockOrganizationPeople}
              currentBundles={[]}
              workflows={mockWorkflowsWithFees}
            />
          </FormContextProvider>
        </Router>
      </AlertProvider>
    );

    await waitFor(() => expect(getByText("Homer Simpson")));

    expect(getByText(/Continue/i).closest("button")).toHaveClass("disabled");

    fireEvent.click(getByText("Homer Simpson"));
    expect(getByText("First Visa"));
    const workflowSelectDiv = getByTestId("workflow-select");
    fireEvent.focus(workflowSelectDiv.querySelector("input"));
    fireEvent.keyDown(workflowSelectDiv.querySelector("input"), {
      key: "ArrowDown",
      code: 40
    });
    fireEvent.keyDown(workflowSelectDiv.querySelector("input"), {
      key: "Enter",
      code: 13
    });

    expect(getByText(/Continue/i).closest("button")).not.toHaveClass(
      "disabled"
    );
  });
});
