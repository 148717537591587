import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import RunningTotal from "../RunningTotal/RunningTotal";
import { Section } from "../SalesFlowComponents/index";
import { gray, grayDarker } from "../../../styles/colors";
import { uniqueArrayByProp } from "../../../utils/arrayMethods";
import ReviewSection from "./ReviewSection";
import ActionFrame from "../ActionFrame";
import { formatCentsToCurrency } from "../../../utils/numberHelpers";
import { formHeaders } from "../../../utils/form";

const WarningBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  grid-gap: 1.5rem;
  background-color: white;
  border-radius: 8px;
  padding: 1rem 2rem;
  margin-top: 1.5rem;

  h3 {
    margin: 0;
    font-weight: 700;
  }
  p {
    margin: 0;
    color: ${grayDarker};
  }

  i {
    margin-right: 1rem;
  }
`;

const LineItem = styled.div`
  margin-top: 1rem;
  p {
    margin: 0;
  }
  li {
    margin: 0;
    font-weight: 700;
  }
  span {
    margin-left: -6px;
  }
`;

export default function ReviewStep(props) {
  const popup = useAlert();
  const history = useHistory();

  const { deal, setDeal, workflows, stepsArray = [], currentStep = {} } = props;

  const {
    id: dealId,
    petitionBundles,
    dealDiscounts,
    installments,
    organization,
    billingPerson
  } = deal;

  const [loading, setLoading] = useState(false);

  const queryAdditionalServiceById = serviceId => {
    const allAdditionalServices = [];
    workflows.forEach(wf => allAdditionalServices.push(wf.additionalServices));
    const uniqueServices = uniqueArrayByProp(
      "id",
      allAdditionalServices.flat()
    );
    const { name: serviceName, amount: serviceAmount } = uniqueServices.find(
      as => as.id === serviceId
    );
    return [serviceName, serviceAmount];
  };

  const submitDeal = async () => {
    try {
      setLoading(true);

      const url = Routes.submit_deal_path(dealId);

      const requestBody = {};

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const json = await response.json();
        setLoading(false);
        json.errors.forEach(err => popup.show(err, { type: "error" }));
      }

      if (response.ok) {
        const json = await response.json();
        setDeal(json);
        setLoading(false);
        popup.show("Deal has been submitted successfully.", {
          type: "success"
        });
        // Reroute to deal show page
        history.push(`/deals/${dealId}`);
      }
    } catch (error) {
      setLoading(false);
      popup.show(error.message, { type: "error" });
    }
  };

  return (
    <>
      <Section>
        <div>
          <ReviewSection header="Petitioner Information">
            <div
              style={{ marginTop: "1rem" }}
              data-testid="petitioner-information-section"
            >
              <LineItem>
                <p>Petitioner</p>
                <h6>{organization?.displayName}</h6>
              </LineItem>
            </div>
          </ReviewSection>
          <ReviewSection header="Beneficiaries & Visas">
            <div data-testid="beneficiary-visas-section">
              {petitionBundles?.map((bundle, i) => {
                const { petition, additionalServiceIds } = bundle;
                const { beneficiary, workflow, representative } = petition;
                return (
                  <div key={bundle.id} style={{ marginTop: "1rem" }}>
                    <LineItem>
                      <p>Beneficiary</p>
                      <h6>{`${beneficiary.firstName} ${beneficiary.lastName}`}</h6>
                    </LineItem>
                    <LineItem>
                      <p>Beneficiary email</p>
                      <h6>{beneficiary.email}</h6>
                    </LineItem>
                    <LineItem>
                      <p>Visa type</p>
                      <h6>{workflow.name}</h6>
                    </LineItem>
                    <LineItem>
                      <p>Point of Contact</p>
                      <h6>
                        {`${representative.firstName} ${representative.lastName}`}
                      </h6>
                    </LineItem>
                    <LineItem>
                      <p>Point of Contact Email</p>
                      <h6>{representative.email}</h6>
                    </LineItem>
                    {petitionBundles.length > 1 &&
                      i !== petitionBundles.length - 1 && (
                        <>
                          <br />
                          <hr />
                        </>
                      )}
                  </div>
                );
              })}
            </div>
          </ReviewSection>
        </div>
      </Section>
      <ActionFrame
        loading={loading}
        stepsArray={stepsArray}
        currentStep={currentStep}
        continueButtonText="Submit deal"
        handleContinue={() => submitDeal()}
      />
    </>
  );
}

ReviewStep.propTypes = {
  deal: PropTypes.object,
  workflows: PropTypes.array,
  stepsArray: PropTypes.array,
  currentStep: PropTypes.object
};
