import { render, within, fireEvent, waitFor } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import { Router } from "react-router-dom";
import { createMemoryHistory } from "history";
import DealIndexPage from "./DealIndexPage";
import { mockDeals } from "../../../testHelpers/dealIndexMocks.new";
import AlertTemplate from "../../Shared/AlertTemplate";

describe("The DealIndexPage component", () => {
  // fetch deals call on render
  beforeEach(() => {
    fetch.mockResponses([JSON.stringify({ deals: mockDeals })]);
  });

  afterEach(() => fetch.resetMocks());

  const history = createMemoryHistory();

  it("renders without crashing", async () => {
    const { container, getAllByText } = render(
      <Router history={history}>
        <AlertProvider template={AlertTemplate}>
          <DealIndexPage />
        </AlertProvider>
      </Router>
    );

    await waitFor(() => getAllByText("Drafts")[0]);

    expect(fetch.mock.calls.length).toEqual(1);
    expect(container);
  });

  it("pulls down the deals from the server when rendered", async () => {
    const { getByText } = render(
      <Router history={history}>
        <AlertProvider template={AlertTemplate}>
          <DealIndexPage />
        </AlertProvider>
      </Router>
    );

    await waitFor(() => {
      expect(fetch.mock.calls.length).toEqual(1);
      getByText("Eric Cartman & 2 more");
    });
  });

  it("sorts the deals within a section by 'last updated' by default", async () => {
    const { getByTestId } = render(
      <Router history={history}>
        <AlertProvider template={AlertTemplate}>
          <DealIndexPage />
        </AlertProvider>
      </Router>
    );

    await waitFor(() => {
      expect(fetch.mock.calls.length).toEqual(1);
    });

    const draftDealRows = within(getByTestId("Drafts")).getAllByTestId(
      "deal-row"
    );

    await waitFor(() =>
      within(draftDealRows[0]).getByText("Eric Cartman & 2 more")
    );

    const pendingDealRows = within(
      getByTestId("Payment Pending")
    ).getAllByTestId("deal-row");

    await waitFor(() => within(pendingDealRows[0]).getByText("Jarvis Witte"));

    const inProgressDealRows = within(
      getByTestId("Payment in Progress")
    ).getAllByTestId("deal-row");
    await waitFor(() => within(inProgressDealRows[0]).getByText("Tiger Woods"));
  });

  it(`sorts the deals chronologically in ascending order when clicking on 
      date modified and in descending order on a second click`, async () => {
    const { getByTestId, getAllByTestId } = render(
      <Router history={history}>
        <AlertProvider template={AlertTemplate}>
          <DealIndexPage />
        </AlertProvider>
      </Router>
    );

    await waitFor(() => {
      expect(fetch.mock.calls.length).toEqual(1);
    });

    fireEvent.click(getAllByTestId("deal-last-modified")[0]);

    const draftDealRows = within(getByTestId("Drafts")).getAllByTestId(
      "deal-row"
    );

    await waitFor(() => within(draftDealRows[0]).getByText("Randy Marsh"));

    fireEvent.click(getAllByTestId("deal-last-modified")[0]);

    const newDraftDealRows = within(getByTestId("Drafts")).getAllByTestId(
      "deal-row"
    );

    await waitFor(() =>
      within(newDraftDealRows[0]).getByText("Eric Cartman & 2 more")
    );
  });

  it("sorts the deals alphabetically by beneficiary name when clicking on 'deal'", async () => {
    const { getByTestId, getAllByTestId } = render(
      <Router history={history}>
        <AlertProvider template={AlertTemplate}>
          <DealIndexPage />
        </AlertProvider>
      </Router>
    );

    await waitFor(() => {
      expect(fetch.mock.calls.length).toEqual(1);
    });

    fireEvent.click(getAllByTestId("deal")[0]);
    const draftDealRows = within(getByTestId("Drafts")).getAllByTestId(
      "deal-row"
    );
    await waitFor(() =>
      within(draftDealRows[0]).getByText("Aaalphonse Mephesto")
    );

    fireEvent.click(getAllByTestId("deal")[0]);
    const newDraftDealRows = within(getByTestId("Drafts")).getAllByTestId(
      "deal-row"
    );
    await waitFor(() => within(newDraftDealRows[0]).getByText("Zz Top"));
  });

  it("displays the appropriate search results when typing in the search bar", async () => {
    const { getByTestId, getAllByTestId } = render(
      <Router history={history}>
        <AlertProvider template={AlertTemplate}>
          <DealIndexPage />
        </AlertProvider>
      </Router>
    );

    await waitFor(() => {
      expect(fetch.mock.calls.length).toEqual(1);
    });

    fireEvent.click(getAllByTestId("next-page")[0]);
    fireEvent.click(getAllByTestId("next-page")[0]);
    fireEvent.click(getAllByTestId("prev-page")[0]);

    fireEvent.change(getByTestId("deal-index-searchbar"), {
      target: { value: "Chef Hayes" }
    });

    await waitFor(() =>
      within(getAllByTestId("deal-row")[0]).getByText("Chef Hayes")
    );
  });
});
