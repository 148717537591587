import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { gray } from "../../styles/colors";

const Main = styled.div`
  border-top: 1px solid ${gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  button {
    margin-top: 0.5rem;
  }
`;

export default function ActionFrame(props) {
  const {
    loading = false,
    continueDisabled,
    stepsArray,
    currentStep,
    continueButtonText = "Continue",
    lastStepText,
    handleContinue
  } = props;

  const currentStepIndex = stepsArray.indexOf(currentStep);
  const nextStep = () => stepsArray[currentStepIndex + 1];
  const prevStep = () => stepsArray[currentStepIndex - 1];

  return (
    <Main>
      {currentStepIndex > 1 && (
        <Link to={prevStep()?.pathname ?? "#"}>
          <p>Back</p>
        </Link>
      )}
      <div />
      <div style={{ display: "grid", justifyItems: "right" }}>
        {loading && (
          <Loader type="ThreeDots" color="#2680FA" height={64} width={64} />
        )}
        {!loading && (
          <>
            {nextStep() && <small>Next: {nextStep().description}</small>}
            {!nextStep() && <small>{lastStepText}</small>}
            <button
              className={`${continueDisabled ? "disabled" : ""}`}
              type="button"
              onClick={() => handleContinue()}
              disabled={continueDisabled}
            >
              {continueButtonText}
            </button>
          </>
        )}
      </div>
    </Main>
  );
}

ActionFrame.propTypes = {
  stepsArray: PropTypes.array,
  currentStep: PropTypes.object,
  continueButtonText: PropTypes.string,
  lastStepText: PropTypes.string
};
