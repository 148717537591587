/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import styled from "styled-components";
import useContextMenu from "react-use-context-menu";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { formatCentsToCurrency } from "../../../../utils/numberHelpers";
import { IndexItem } from "../../../Shared/IndexItem/IndexItem";

import { white, grayLight, gray, blue } from "../../../../styles/colors";
import { formHeaders } from "../../../../utils/form";
import { ContextMenu } from "../../../Shared/ContextMenu/ContextMenu";

const IndexRow = styled.div`
  background: ${white};
  border: 1px solid ${grayLight};
  border-radius: 8px;
  margin-bottom: 1rem;
  border-left: 4px solid transparent;

  &:hover {
    border-left: 4px solid ${blue};
    box-shadow: 0px 3px 5px ${gray};
    cursor: pointer;
  }

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  padding: 0.75rem;
`;

const dealStatusMapping = {
  draft: "Draft",
  submitted: "Pending",
  active: "Payments in progress"
};

export default function DealRow(props) {
  const { deal, setDealsState } = props;

  const popup = useAlert();

  const [
    bindMenu,
    bindMenuItems,
    useContextTrigger,
    { setVisible: setContextMenuVisible }
  ] = useContextMenu();
  const [bindTrigger] = useContextTrigger({});
  const [showArchived, setShowArchived] = useState(deal.archived);

  const history = useHistory();

  const handleArchive = async unarchive => {
    // Pass 'unarchive' or true to this function to unarchive

    try {
      const url = unarchive
        ? Routes.unarchive_deal_path(deal.id)
        : Routes.archive_deal_path(deal.id);

      const response = await fetch(url, {
        method: "POST",
        headers: formHeaders(),
        body: JSON.stringify({})
      });

      if (!response.ok) {
        popup.show(response.error.message, { type: "error" });
      }

      if (response.ok) {
        const json = await response.json();
        setContextMenuVisible(false);
        setDealsState(prevState =>
          prevState.map(d => (d.id === deal.id ? json : d))
        );
        setShowArchived(json.archived);
        const message = unarchive
          ? "Your deal has been unarchived"
          : "Your deal has been archived";
        popup.show(message, { type: "success" });
      }
    } catch (error) {
      popup.show("Something went wrong. Please contact Legalpad Engineering.", {
        type: "error"
      });
    }
  };

  const DealContextMenu = () => (
    <ContextMenu {...bindMenu}>
      {deal.state !== "draft" && <a>No actions available</a>}
      {!showArchived && deal.state === "draft" && (
        <a
          onClick={() =>
            window.confirm("Are you sure you want to archive this deal?") &&
            handleArchive()
          }
        >
          Archive
        </a>
      )}
      {showArchived && deal.state === "draft" && (
        <a onClick={() => handleArchive("unarchive")}>Unarchive</a>
      )}
    </ContextMenu>
  );

  return (
    <div data-testid="deal-row">
      <DealContextMenu />
      <IndexRow
        {...bindTrigger}
        key={deal.id}
        id={`deal-row-${deal.id}`}
        onClick={() => history.push(`/deals/${deal.id}`)}
      >
        <div>
          <IndexItem bold marginBottom>
            {// eslint-disable-next-line no-nested-ternary
            !deal.dealBeneficiaries?.length
              ? "-"
              : deal.dealBeneficiaries?.length > 1
              ? `${deal.dealBeneficiaries[0]} & ${deal.dealBeneficiaries
                  ?.length - 1} more`
              : deal.dealBeneficiaries[0]}
          </IndexItem>
          <IndexItem>{deal.organization.displayName}</IndexItem>
        </div>
        <IndexItem grayDark>{deal.author.fullName}</IndexItem>
        <IndexItem grayDark>
          {deal.finalPriceData.pay_total
            ? formatCentsToCurrency(deal.finalPriceData.pay_total)
            : "-"}
        </IndexItem>
        <IndexItem>{dealStatusMapping[deal.state]}</IndexItem>
        <IndexItem grayDark>{deal.updatedAtHumanized}</IndexItem>
      </IndexRow>
    </div>
  );
}
