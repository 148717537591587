import React from "react";
import { Provider as AlertProvider, positions } from "react-alert";
import AlertTemplate from "../Shared/AlertTemplate";
import SalesFlow from "./SalesFlow";
import { AuthContextProvider } from "../Shared/ContextProviders/AuthProvider";

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3500,
  offset: "30px"
  // you can also just use 'scale'
};

export default function SalesFlowWrapper(props) {
  return (
    <AuthContextProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <SalesFlow {...props} />
      </AlertProvider>
    </AuthContextProvider>
  );
}

SalesFlowWrapper.propTypes = {};
