import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { formHeaders } from "../../utils/form";
import RouteWrapper from "./RouteWrapper";
import { FormContextProvider } from "./FormStateProvider";

export default function DataProvider(props) {
  const popup = useAlert();
  const location = useLocation();
  const history = useHistory();

  const { deal, setDeal } = props;

  // Loading states
  const [dealLoading, setDealLoading] = useState(false);
  const [fixturesLoading, setFixturesLoading] = useState(false);

  // Data for provision
  const [petitionerItems, setPetitionerItems] = useState([]);
  const [organizationId, setOrganizationId] = useState(deal?.organization?.id);
  const [organizationPeople, setOrganizationPeople] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [installmentOptions, setInstallmentOptions] = useState([]);

  // Pay total for display
  const [payTotal, setPayTotal] = useState(null);

  const parsedDealId = location.pathname.split("/")[2];

  const fetchPeople = async orgId => {
    try {
      const url = Routes.people_organization_path(orgId);

      const response = await fetch(url, {
        method: "GET",
        headers: formHeaders()
      });

      if (!response.ok) {
        popup.show(
          `Something went wrong: \n ${response.status} ${response.statusText}`,
          { type: "error" }
        );
      }

      if (response.ok) {
        const json = await response.json();
        setOrganizationPeople(json);
        setDealLoading(false);
      }
    } catch (error) {
      popup.show(error.message, { type: "error" });
    }
  };

  useEffect(() => {
    const fetchDeal = async dealId => {
      try {
        setDealLoading(true);
        const url = Routes.deal_path(dealId);

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          popup.show("Deal could not be fetched, redirecting", {
            type: "error"
          });
          history.push("/deals/new");
        }

        if (response.ok) {
          const json = await response.json();
          setDeal(json);
          setOrganizationId(json.organization.id);
          fetchPeople(json.organization.id);
        }
      } catch (error) {
        popup.show(error.message, { type: "error" });
      }
    };

    if (parsedDealId && parsedDealId !== "new") {
      fetchDeal(parsedDealId);
    }
  }, [parsedDealId]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const url = Routes.organizations_path();

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });
        if (!response.ok) {
          popup.show(
            `Something went wrong: \n ${response.status} ${response.statusText}`,
            { type: "error" }
          );
        }

        if (response.ok) {
          const json = await response.json();
          const items = json.items.map(org => ({
            id: org.id,
            label: org.to_s
          }));
          setPetitionerItems(items);
        }
      } catch (error) {
        popup.show(error.message, { type: "error" });
      }
    };
    const fetchWorkflows = async () => {
      try {
        // eslint-disable-next-line no-undef
        const url = Routes.workflows_path();

        const response = await fetch(url, {
          method: "GET",
          headers: formHeaders()
        });

        if (!response.ok) {
          popup.show(
            `Something went wrong: \n ${response.status} ${response.statusText}`,
            { type: "error" }
          );
        }

        if (response.ok) {
          const json = await response.json();
          setWorkflows(json.items);
        }
      } catch (error) {
        popup.show(error.message, { type: "error" });
      }
    };

    setFixturesLoading(true);
    Promise.all([fetchOrganizations(), fetchWorkflows()]).then(() => {
      setFixturesLoading(false);
    });
  }, []);

  useEffect(() => {
    if (organizationId) {
      fetchPeople(organizationId);
    }
    if (!organizationId) {
      setOrganizationPeople([]);
    }
  }, [organizationId]);

  return (
    <FormContextProvider>
      <RouteWrapper
        deal={deal}
        setDeal={setDeal}
        payTotal={payTotal}
        setPayTotal={setPayTotal}
        dealLoading={dealLoading}
        fixturesLoading={fixturesLoading}
        petitionerItems={petitionerItems}
        organizationId={organizationId}
        setOrganizationId={setOrganizationId}
        organizationPeople={organizationPeople}
        setOrganizationPeople={setOrganizationPeople}
        workflows={workflows}
      />
    </FormContextProvider>
  );
}
