import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Collapse from "react-css-collapse";
import { grayLight, grayDark, grayDarker, white } from "../../../styles/colors";
import { formatCentsToCurrency } from "../../../utils/numberHelpers";

const DetailBox = styled.div`
  background: ${white};
  border: 1px solid ${grayLight};
  border-radius: 6px;
  margin-right: 3rem;
  padding: 1rem;
`;

const DetailRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  p {
    margin: 0;
    font-size: ${({ diminished }) => (diminished ? "14px" : "")};
  }
`;

export default function PetitionBundleDetails(props) {
  const { petitionBundle } = props;
  const { petition, finalPriceData } = petitionBundle;
  // alias to camelcase because Jbuilder sucks at handling jsonb columns
  const {
    price_total: priceTotal,
    base_fees: baseFees,
    additional_services: additionalServices
  } = finalPriceData;
  const allFees = baseFees.concat(additionalServices);
  const { beneficiary, workflow, id } = petition;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <DetailBox key={petitionBundle.id}>
      <DetailRow>
        <a
          style={{
            color: grayDarker,
            outline: "none",
            display: "inline-flex",
            alignItems: "center"
          }}
          role="button"
          tabIndex={0}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          onKeyDown={() => setDropdownOpen(!dropdownOpen)}
        >
          <i
            title="arrow"
            style={{ marginRight: "1rem" }}
            className={
              dropdownOpen
                ? "u_color-blue far fa-chevron-down"
                : "u_color-blue far fa-chevron-right"
            }
          />
          <p>{`${beneficiary.firstName} ${beneficiary.lastName}`}</p>
        </a>

        <p>{workflow.name}</p>
        <p>{formatCentsToCurrency(priceTotal)}</p>
        {/* eslint-disable-next-line no-undef */}
        <a href={Routes.petition_path(id)}>Go to petition</a>
      </DetailRow>
      <Collapse data-testid="css-collapse" isOpen={dropdownOpen}>
        {allFees.map((fee, i) => (
          <DetailRow diminished key={i}>
            <p />
            <p style={{ color: grayDark }}>{fee.name}</p>
            <p style={{ color: grayDark }}>
              {formatCentsToCurrency(fee.amount)}
            </p>
            <p />
          </DetailRow>
        ))}
      </Collapse>
    </DetailBox>
  );
}

PetitionBundleDetails.propTypes = {
  petitionBundle: PropTypes.object
};
