import { render } from "@testing-library/react";
import { Provider as AlertProvider } from "react-alert";
import DealRow from "./DealRow";
import { mockDeals } from "../../../../testHelpers/dealIndexMocks";
import AlertTemplate from "../../../Shared/AlertTemplate";

describe("The Deal component", () => {
  it("renders without crashing", () => {
    const { container } = render(
      <AlertProvider template={AlertTemplate}>
        <DealRow deal={mockDeals[1]} />
      </AlertProvider>
    );
    expect(container);
  });

  it("displays the billing person full name and organization display name", () => {
    const { getByText } = render(
      <AlertProvider template={AlertTemplate}>
        <DealRow deal={mockDeals[1]} />
      </AlertProvider>
    );
    expect(getByText("Some Dude & 2 more"));
    expect(getByText("Springfield parents assoc"));
  });

  it("displays the final price pay total if it is present", () => {
    const { getByText } = render(
      <AlertProvider template={AlertTemplate}>
        <DealRow deal={mockDeals[2]} />
      </AlertProvider>
    );
    expect(getByText("$9,937"));
  });

  it("does not display the final price total if it is missing", () => {
    const { getByText } = render(
      <AlertProvider template={AlertTemplate}>
        <DealRow deal={mockDeals[0]} />
      </AlertProvider>
    );
    expect(getByText("-"));
  });

  describe("state", () => {
    it("displays a friendly name for the draft deal state", () => {
      const deal = mockDeals[0];
      deal.state = "draft";

      const { getByText } = render(
        <AlertProvider template={AlertTemplate}>
          <DealRow deal={deal} />
        </AlertProvider>
      );

      expect(getByText("Draft"));
    });

    it("displays a friendly name for the submitted deal state", () => {
      const deal = mockDeals[0];
      deal.state = "submitted";

      const { getByText } = render(
        <AlertProvider template={AlertTemplate}>
          <DealRow deal={deal} />
        </AlertProvider>
      );

      expect(getByText("Pending"));
    });

    it("displays a friendly name for the active deal state", () => {
      const deal = mockDeals[0];
      deal.state = "active";
      const { getByText } = render(
        <AlertProvider template={AlertTemplate}>
          <DealRow deal={deal} />
        </AlertProvider>
      );

      expect(getByText("Payments in progress"));
    });
  });
});
