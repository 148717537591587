import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { blue, grayDarker } from "../../styles/colors";

const Centered = styled.div`
  max-width: 72rem;
  margin: auto;
  text-align: center;
`;

const Nav = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

const NavItem = styled.div`
  padding: 1rem;
  font-weight: 500;
  color: ${props => (props.stepActive ? blue : grayDarker)};
  border-bottom: ${props => (props.stepActive ? `5px solid ${blue}` : "")};
`;

export default function StepNav(props) {
  const { deal } = props;
  const location = useLocation();

  const petitionerStepPath = deal.id
    ? `/deals/${deal.id}/petitioner`
    : "/deals/new";

  return (
    <Centered>
      <Nav>
        <NavItem stepActive={location.pathname === petitionerStepPath}>
          Petitioner
        </NavItem>

        {/* Allow user to navigate with the header nav when the deal allows */}
        {deal.id ? (
          <Link to={`/deals/${deal.id}/beneficiaries`}>
            <NavItem
              stepActive={
                location.pathname === `/deals/${deal.id}/beneficiaries`
              }
            >
              Beneficiaries & Visas
            </NavItem>
          </Link>
        ) : (
          <NavItem
            stepActive={location.pathname === `/deals/${deal.id}/beneficiaries`}
          >
            Beneficiaries & Visas
          </NavItem>
        )}
        {deal?.petitionBundles?.length > 0 ? (
          <Link to={`/deals/${deal.id}/contacts`}>
            <NavItem
              stepActive={location.pathname === `/deals/${deal.id}/contacts`}
            >
              Point of Contact
            </NavItem>
          </Link>
        ) : (
          <NavItem
            stepActive={location.pathname === `/deals/${deal.id}/contacts`}
          >
            Point of Contact
          </NavItem>
        )}
        {deal?.petitionBundles?.length > 0 ? (
          <Link to={`/deals/${deal.id}/review`}>
            <NavItem
              stepActive={location.pathname === `/deals/${deal.id}/review`}
            >
              Review
            </NavItem>
          </Link>
        ) : (
          <NavItem
            stepActive={location.pathname === `/deals/${deal.id}/review`}
          >
            Review
          </NavItem>
        )}
      </Nav>
    </Centered>
  );
}
